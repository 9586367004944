import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpcallsService } from "../services/httpcalls.service";
import { CookieService } from "ngx-cookie-service";
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment';
import { UtilService } from "../services/util.service";

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  loginForm = new FormGroup({
    useremail: new FormControl("", [Validators.email, Validators.required]),
    password: new FormControl("", [
      Validators.minLength(6),
      Validators.required,
    ]),
    confirmpassword: new FormControl("", [
      Validators.minLength(6),
      Validators.required,
    ]),
  });
  checked = false;
  id: any;
  authenticationUrl:any
  get UserEmail() {
    return this.loginForm.get("useremail");
  }
  get UserPassword() {
    return this.loginForm.get("password");
  }
  constructor (
    private db: AngularFirestore,
    private router: Router,
    private cookie: CookieService,
    private http: HttpcallsService,
    private utilservice:UtilService
  ) { }

  ngOnInit() {
    this.authenticationUrl = environment.authencationApi;
  }
  login(data) {
   if(!this.checked){
    let json = {
      useremail: data.value.useremail,
    };
    let check = json.useremail.includes("@assethealth.com");
    let requestBody: any = {
      email: '',
      productId:"SONA_BLW"
    };
    requestBody['email'] = json.useremail;
    let body: any = {
      encrypted: '',
    };
    body['encrypted'] = this.utilservice.encrypt(requestBody, environment.authenticationKey);
    let endPoint = 'passwordRecovery';
    this.http
      .blpUsersAuthentication(body, `${this.authenticationUrl}/${endPoint}`)
      .subscribe(
        (response: any) => {
          let result: any = this.utilservice.decrypt(
            response['encrypted'],
            environment.authenticationKey
          );
          result = JSON.parse(result);
          if (result.status == 'success') {
            this.utilservice.showSnakbarMessage(
              'success',
              result.message,
              'end',
              'top'
            );
            this.router.navigate(['/login'])
          } else {
            this.utilservice.showSnakbarMessage(
              'error',
            'User Not Found',
              'end',
              'top'
            );
          }
        },
        (err: any) => {
          console.log(err);
          this.utilservice.showSnakbarMessage(
            'error',
            err.toString(),
            'end',
            'top'
          );
        }
      )
    // if (check) {

    //   this.db.collection("/Asset_OEE/SONA/User", ref => ref.where("userid", "==",json.useremail)).snapshotChanges().subscribe((data:any) =>{

    //     if(data){
    //     this.id = data[0].payload.doc.id;
    //     this.checked = true;
    //     }else{
    //       Swal.fire("Invalid Username");
    //     }
    //   })
    // } else {
    //   Swal.fire("Sorry! Wrong Username");
    // }
   }
   else{
    let json = {
      useremail: data.value.useremail,
      password:data.value.password,
    };
    // this.db.collection("/Asset_OEE/SONA/User", ref => ref.where("userid", "==", json.useremail)).get().subscribe(d =>{
    //   if(d.size > 0){
    //     let doc = d[0].data();
    //     if(doc.password){

    //     }
    //   }
    // })
    this.db.collection("/Asset_OEE/SONA/User").doc(this.id).update({password:json.password}).then(() =>{
      Swal.fire("Password Updated Successfully!", "", "success");
      this.loginForm.reset();
      this.checked =false;
    })
   }
  }
}

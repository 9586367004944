import {
  Injectable
} from '@angular/core';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import {
  environment
} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HistorySocketService {
  private socket;
  private historyUpdated = new Subject();

  constructor() {}

  setupSocketConnection() {
    if (!this.socket && environment.SERVICE_CALL == "http") {
      this.socket = io(environment.SOCKET_ENDPOINT);
      this.socket.on('history_room', (data) => {
        if(data){
            this.historyUpdated.next(data);
        }
      });
    }
  }
  getHistoryUpdateListener() {
    return this.historyUpdated.asObservable();
  }

}

import { NestedTreeControl } from "@angular/cdk/tree";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { DataService } from "../services/data.service";
import { FirestoreService } from "../services/firestore.service";
import { HttpcallsService } from "../services/httpcalls.service";
import * as _ from "lodash";
import { AngularFirestore } from "@angular/fire/firestore";
import * as CryptoJS from "crypto-js";
import  {NgbModal}  from "@ng-bootstrap/ng-bootstrap";
interface FoodNode {
  name: string;
  level?: string;
  index?: number;
  expanded?: boolean;
  disabled?: boolean;
  children?: FoodNode[];
}

const OEE_DATA: FoodNode[] = [
  {
    name: "Sona BLW",
    level: "fleet",
    index: 0,
    expanded: true,
    disabled: false,
  },
  {
    name: "Unit-1,Begumpur Khatola",
    level: "plant",
    index: 0,
    disabled: false,
    expanded: true,
    children: [
      {
        name: "line 1",
        level: "unit",
        expanded: true,
        disabled: false,
        children: [
          { name: "Induction Heater", level: "asset" },
          { name: "Rough Press", level: "asset" },
        ],
      },
    ],
  },
];
const ASSET_HEALTH_DATA: FoodNode[] = [
  {
    name: "Fleet",
    level: "fleet",
    disabled: false,
    expanded: true,
    children: [
      {
        name: "Plant1",
        level: "plant",
        disabled: false,
        expanded: true,
        children: [
          {
            name: "Line 1",
            level: "unit",
            expanded: true,
            children: [
              { name: "Press", level: "asset" },
              { name: "Induction  Heater", level: "asset" },
              { name: "Slow cooling Conveyour", level: "asset" },
            ],
          },
        ],
      },
      {
        name: "Plant2",
        level: "plant",
        disabled: true,
        expanded: false,
        children: [
          {
            name: "Line 1",
            expanded: false,
            level: "unit",
            children: [
              { name: "Press", level: "asset" },
              { name: "Induction  Heater", level: "asset" },
              { name: "Slow cooling Conveyour", level: "asset" },
            ],
          },
        ],
      },
      {
        name: "Plant3",
        disabled: true,
        expanded: false,
        level: "plant",
        children: [
          {
            name: "Line 1",
            level: "unit",
            expanded: false,
            children: [
              { name: "Press", level: "asset" },
              { name: "Induction  Heater", level: "asset" },
              { name: "Slow cooling Conveyour", level: "asset" },
            ],
          },
        ],
      },
      {
        name: "Plant4",
        level: "plant",
        disabled: true,
        expanded: false,
        children: [
          {
            name: "Line 1",
            level: "unit",
            expanded: false,
            children: [
              { name: "Press", level: "asset" },
              { name: "Induction  Heater", level: "asset" },
              { name: "Slow cooling Conveyour", level: "asset" },
            ],
          },
        ],
      },
    ],
  },
];
@Component({
  selector: "app-layout-container",
  templateUrl: "./layout-container.component.html",
  styleUrls: ["./layout-container.component.scss"],
})
export class LayoutContainerComponent implements OnInit, AfterViewInit {
  @ViewChild("tree", { static: true }) tree;
  version = environment.version;
  levelId = localStorage.getItem("levelid");
  role = localStorage.getItem("role");
  selectednode = this.levelId;
  treeControl = new NestedTreeControl<FoodNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<FoodNode>();
  sidebar: boolean = true;
  selectedModule: string = "assethealth";
  submodule: string = "overview";
  oee = [];
  loading = false;
  searchkey: string;
  db: any;
  activeThem: string = "white";
  switch_color = "#277ef3";
  switch_checked: boolean;
  tab_bar: boolean = true;
  isPredictAI: any;
  twoHourInterval;
  message: any;
  url: any;
  display: string = "none";
  constructor(
    private router: Router,
    private service: DataService,
    private http: HttpcallsService,
    private firestore: AngularFirestore,
    private fs: FirestoreService,
    private route: ActivatedRoute,
    private cf: ChangeDetectorRef
  ) // private modalService: NgbModal
  {}
  ngOnInit() {
    this.service.loader.subscribe((data: any) => {
      this.loading = data;
      this.cf.detectChanges();
    });
    let project = JSON.parse(sessionStorage.getItem("isPredictAI"));
    this.isPredictAI = project != undefined ? project : true;
    if (this.activeThem == "white") {
      this.switch_checked = true;
    } else {
      this.switch_checked = false;
    }
    this.db = this[environment.SERVICE_CALL];
    this.service.selectednode2.subscribe((data: any) => {
      if (Object.keys(data).length == 0) {
        data = JSON.parse(localStorage.getItem("selectedNode2"));
      }
      this.selectednode = data.id;
    });
    let params = {
      customerId: "",
    };
    // if (localStorage.getItem("selected")) {
    //   if (localStorage.getItem("selected") == "assethealth") {
    //     params.customerId = "SONA";
    //     this.selectednode = localStorage.getItem("selectednode");
    //   }
    //   this.getCustomer(params);
    // }
    // if (this.service.selectednode != undefined) {
    //   this.service.selectednode.subscribe((data) => {
    //     this.selectednode = data;
    //   });
    // }
    // if (this.service.activatedmodule != undefined) {
    //   this.service.activatedmodule.subscribe((data) => {
    //     this.selectedModule = data;
    //   });
    // }
    if (this.service.activatedsubmodule != undefined) {
      this.service.activatedsubmodule.subscribe((data) => {
        this.submodule = data;
      });
    }
    if (localStorage.getItem("submodule")) {
      this.submodule = localStorage.getItem("submodule");
    }
    this.service.show_navbars.subscribe((data) => {
      this.sidebar = data.sidebar;
      this.tab_bar = data.tabbar;
      this.cf.detectChanges();
    });
    // this.twoHourInterval = setInterval(() => {
    //   this.show_message();
    // }, 5000);
  }
  toggleThem(value) {
    if (value == false) {
      this.service.setActiveThem("black");
      this.activeThem = "black";
    } else {
      this.service.setActiveThem("white");
      this.activeThem = "white";
    }
  }
  getCustomer(params) {
    this.db.getCustomer(params).subscribe((data: any) => {
      if (environment.SERVICE_CALL == "fs") {
        this.formattedData(data);
      } else {
        this.oee.push(data.data);
        this.dataSource.data = this.oee;
        this.calhierarchy(this.oee);
      }
      if (this.oee[0].expanded != undefined) {
        this.service.send_h_data(this.oee);
      }
      this.service.getheiratchydata.subscribe((h_data: FoodNode[]) => {
        this.dataSource.data = [];
        this.dataSource.data = h_data;
      });
    });
  }
  formattedData(data) {
    let customerData: any[] = [];
    let documents = [];
    this.dataSource.data = [];
    this.oee = [];
    data.forEach((element) => {
      documents.push(element.data());
    });
    if (documents && documents.length > 0) {
      let obj = {};
      obj["id"] = documents[0].customerId;
      obj["name"] = documents[0].customer;
      obj["level"] = "fleet";
      obj["index"] = 0;
      customerData[0] = obj;
      let siteNum = 0;
      _.forOwn(_.groupBy(documents, "siteId"), (value, key) => {
        siteNum++;
        var siteData = {
          id: key,
          name: value[0].site,
          level: "plant",
        };
        _.forOwn(_.groupBy(value, "lineId"), (value, key) => {
          var lineData = {
            id: key,
            name: value[0].line,
            level: "unit",
          };
          _.forOwn(_.groupBy(value, "assetcode"), (value, key) => {
            var assetData = {
              id: key,
              level: "asset",
              name: value[0].asset,
            };
            if (!lineData["children"]) {
              lineData["children"] = [assetData];
            } else {
              lineData["children"].push(assetData);
            }
          });
          if (!siteData["children"]) {
            siteData["children"] = [lineData];
          } else {
            siteData["children"].push(lineData);
          }
        });
        customerData[siteNum] = siteData;
        customerData[1]["index"] = 0;
      });
      if (this.role == "fleet") {
        this.oee = customerData;
        this.dataSource.data = this.oee;
        this.calhierarchy(customerData);
      } else {
        this.requiredData(customerData);
      }
    }
  }
  requiredData(data) {
    let result;
    data.forEach((d) => {
      if (d.id == this.levelId && d.level == this.role) {
        result = d;
        this.oee.push(result);
        this.dataSource.data = this.oee;
        this.calhierarchy(this.oee);
      } else {
        if (d.children) {
          this.requiredData(d.children);
        }
      }
    });
  }
  calhierarchy(data) {
    data.forEach((asset) => {
      if (asset.children != undefined && asset.children.length > 0) {
        asset["expanded"] = true;
        asset["disable"] = false;
        this.calhierarchy(asset.children);
      } else {
        if (this.role == "site manager") {
          asset["disable"] = false;
        }
        let node = JSON.parse(localStorage.getItem("selectednode"));
        this.navigate(node);
      }
    });
  }
  ngAfterViewInit() {}
  hasChild = (_: number, node: FoodNode) =>
    !!node.children && node.children.length > 0;
  logNode(node?) {
    if (!node.disabled) {
      let level = node.level;
      if (
        node.level == "assettype" ||
        node.level == "unittype" ||
        node.level == "planttype"
      ) {
        if (node.children != undefined) {
          this.navigate(node.children[0]);
        }
      } else {
        this.navigate(node);
      }
    }
  }
  navigate(node) {
    let level = node.level;
    if (level == "asset") {
      localStorage.setItem("activate_tab", JSON.stringify(false));
    } else {
      localStorage.setItem("activate_tab", JSON.stringify(true));
    }
    this.service.sendselectednode2(node);
    localStorage.setItem("selectednode", JSON.stringify(node));
    if (level == "plant" && this.selectedModule == "oee") {
      if (this.submodule == "dashboard") {
        this.router.navigateByUrl(
          "/common/oee/oee/dashboard/plant-level-dashboard"
        );
      } else if (this.submodule == "availability") {
        this.router.navigateByUrl(
          "/common/oee/oee/availability/plant-level-availability"
        );
      } else if (this.submodule == "performance") {
        this.router.navigateByUrl(
          "/common/oee/oee/performance/plant-level-performance"
        );
      } else if (this.submodule == "quality") {
        this.router.navigateByUrl(
          "/common/oee/oee/quality/plant-level-quality"
        );
      }
    } else if (level == "unit" && this.selectedModule == "oee") {
      if (this.submodule == "dashboard") {
        this.router.navigateByUrl(
          "/common/oee/oee/dashboard/unit-level-dashboard"
        );
      } else if (this.submodule == "availability") {
        this.router.navigateByUrl(
          "/common/oee/oee/availability/unit-level-availability"
        );
      } else if (this.submodule == "performance") {
        this.router.navigateByUrl(
          "/common/oee/oee/performance/unit-level-performance"
        );
      } else if (this.submodule == "quality") {
        this.router.navigateByUrl("/common/oee/oee/quality/unit-level-quality");
      }
    } else if (level == "asset" && this.selectedModule == "oee") {
      if (this.submodule == "dashboard") {
        this.router.navigateByUrl(
          "/common/oee/oee/dashboard/asset-level-dashboard"
        );
      } else if (this.submodule == "availability") {
        this.router.navigateByUrl(
          "/common/oee/oee/availability/asset-level-availability"
        );
      } else if (this.submodule == "performance") {
        this.router.navigateByUrl(
          "/common/oee/oee/performance/asset-level-performance"
        );
      } else if (this.submodule == "quality") {
        this.router.navigateByUrl(
          "/common/oee/oee/quality/asset-level-quality"
        );
      }
    } else if (level == "fleet" && this.selectedModule == "oee") {
      if (this.submodule == "dashboard") {
        this.router.navigateByUrl(
          "/common/oee/oee/dashboard/fleet-level-dashboard"
        );
      } else if (this.submodule == "availability") {
        this.router.navigateByUrl(
          "/common/oee/oee/availability/fleet-level-availability"
        );
      } else if (this.submodule == "performance") {
        this.router.navigateByUrl(
          "/common/oee/oee/performance/fleet-level-performance"
        );
      } else if (this.submodule == "quality") {
        this.router.navigateByUrl(
          "/common/oee/oee/quality/fleet-level-quality"
        );
      }
    } else if (level == "fleet" && this.selectedModule == "assethealth") {
      if (this.submodule == "overview") {
        this.router.navigateByUrl(
          "/common/predictive-maintenance/pm/fleet-level-overview"
        );
      } else if (this.submodule == "indicators") {
        this.router.navigate(["/common/predictive-maintenance/pm/indicators"]);
      } else if (this.submodule == "alerts") {
        this.router.navigate(["common/predictive-maintenance/pm/alerts/alert"]);
      }
    } else if (level == "plant" && this.selectedModule == "assethealth") {
      if (this.submodule == "overview") {
        this.router.navigateByUrl(
          "/common/predictive-maintenance/pm/plant-level-overview"
        );
      } else if (this.submodule == "indicators") {
        this.router.navigate(["/common/predictive-maintenance/pm/indicators"]);
      } else if (this.submodule == "alerts") {
        this.router.navigate(["common/predictive-maintenance/pm/alerts/alert"]);
      }
    } else if (level == "unit" && this.selectedModule == "assethealth") {
      if (this.submodule == "overview") {
        this.router.navigateByUrl(
          "/common/predictive-maintenance/pm/line-level-overview"
        );
      } else if (this.submodule == "indicators") {
        this.router.navigate(["/common/predictive-maintenance/pm/indicators"]);
      } else if (this.submodule == "alerts") {
        this.router.navigate(["common/predictive-maintenance/pm/alerts/alert"]);
      }
    } else if (level == "asset" && this.selectedModule == "assethealth") {
      if (this.submodule == "overview") {
        if (node.name == "Induction Heater") {
          this.router.navigateByUrl(
            "/common/predictive-maintenance/pm/asset-level-overview"
          );
        } else if (node.name == "Rough Press" || node.name == "Finish Press") {
          this.router.navigate([
            "/common/predictive-maintenance/pm/press-asset-overview",
            node.id,
          ]);
        } else if (node.name == "Dilute System") {
          this.router.navigateByUrl(
            "/common/predictive-maintenance/pm/dilute-system"
          );
        } else if (node.name == "Slow cooling conveyer") {
          this.router.navigateByUrl(
            "/common/predictive-maintenance/pm/cooling-conveyor"
          );
        }
      } else if (this.submodule == "indicators") {
        this.router.navigate(["/common/predictive-maintenance/pm/indicators"]);
      } else if (this.submodule == "alerts") {
        this.router.navigate(["common/predictive-maintenance/pm/alerts/alert"]);
      }
    }
  }
  changeState(node) {
    node.expanded = !node.expanded;
  }
  sidenavtoggle() {
    setTimeout(() => {
      this.sidebar = false;
      this.tab_bar = false;
    }, 0);
  }
  logout() {
    this.router.navigate(["/login"]);
    localStorage.clear();
    sessionStorage.clear();
  }
  // search
  onkeyup(value: string): void {
    this.dataSource.data = this.search(this.oee, value);
  }
  search(items: FoodNode[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.name, term)) {
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        const newItems = this.search(item.children, term);
        if (newItems.length > 0) {
          acc.push({ name: item.name, children: newItems, expanded: true });
        }
      }
      return acc;
    }, []);
  }
  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }
  clearSearch() {
    this.searchkey = "";
    this.dataSource.data = this.oee;
  }
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse("" + value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }
  loginTo() {
    this.service.activateloader(true);
    if (this.isPredictAI) {
      this.isPredictAI = !this.isPredictAI;
      const useremail = "adminblp@assethealth.com";
      const password = "123456";
      let encrypted = this.set("123456$#@$^@1ERF", password);
      this.firestore
        .collection("/ConserveAI_Users", (ref) =>
          ref
            .where("useremail", "==", useremail)
            .where("password", "==", encrypted)
        )
        .get()
        .subscribe(
          (data) => {
            this.service.activateloader(false);
            if (data.size == 1) {
              let userCollection;
              data.forEach((element) => {
                userCollection = element.data();
              });
              // console.log(userCollection);
              sessionStorage.setItem("isLoggedIn", JSON.stringify(true));
              sessionStorage.setItem("site", userCollection.site);
              sessionStorage.setItem("user", userCollection.username);
              sessionStorage.setItem("customer", userCollection.organisation);
              sessionStorage.setItem("department", userCollection.department);
              sessionStorage.setItem("useremail", userCollection.useremail);
              sessionStorage.setItem("landing", "/portfolio");
              sessionStorage.setItem("role", userCollection.role);
              sessionStorage.setItem("services", userCollection.services);
              sessionStorage.setItem("uid", userCollection.customer_id);
              // Set the user ID using signed-in user_id.
              this.router.navigate(["common/conserve-ai", "energy-meters"]);
            } else {
              alert("Do not have Access!");
            }
          },
          (err) => {
            this.service.activateloader(false);
            alert("Do not have Access!");
          }
        );
    } else {
      this.isPredictAI = !this.isPredictAI;
      this.router.navigate(["/common/predictive-maintenance/pm/overview"]);
    }
    sessionStorage.setItem("isPredictAI", JSON.stringify(this.isPredictAI));
    this.service.activateloader(false);
  }
  async show_message() {
    let prev_months = {
      address: `/site_url_change`,
      conditions: [],
      type: "history",
    };
    if ((this.display != "block")) {
      let response: any = await this.fs.getFireBaseData(prev_months);
      if (response.status && response.status == "success") {
        this.message = response["data"][0]["message"];
        this.url = response["data"][0]["new_url"];
        this.display = "block";
      }
    }
  }
   navigate_back(){
    this.router.navigate(['/login'])
    this.display='none'
    clearInterval(this.twoHourInterval)
   }
}

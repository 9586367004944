import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";
import moment from "moment";

@Component({
  selector: "app-basicspline-chart",
  templateUrl: "./basicspline-chart.component.html",
  styleUrls: ["./basicspline-chart.component.scss"],
})
export class BasicsplineChartComponent implements OnInit {
  @Input("data")
  set in(val) {
    console.log(val);
    this.plotChart(val);
  }
  highcharts = highcharts;
  columnChartOptions = {};
  chartOptions = {};
  constructor() {}

  ngOnInit() {}
  // chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
  //   setTimeout(() => {
  //     chart.reflow();
  //   }, 100);
  // };
  plotChart(data) {
    return (this.chartOptions = {
      chart: {
        type: "spline",
        reflow: true,
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        labels: {
          overflow: "justify",
        },
        // labels: {
        //   style: {
        //      color: 'var(--graph-text-color)',
        //     fontSize:12
        //   },
        //   step:1,
        //   formatter: function () {
        //     if (data.format || data.format != undefined) {
        //       return moment(this.value).format("HH:mm:ss");
        //     } else {
        //       return this.value;
        //     }
        //   },
        // },
      },
      yAxis: data.yaxis,
      colors: ["#2A93FB", "#FFB083", "red", "green"],
      column: {
        colorByPoint: true,
      },
      tooltip: {
        split: true,
        shape: "sqaure",
      },

      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 3,
            },
          },
          marker: {
            enabled: false,
          },
          pointInterval: 3600000, // one hour
          pointStart: Date.UTC(2022, 2, 15, 0, 0, 0),
        },
      },
      legend: {
        layout: "horizontal",
        align: "left",
        verticalAlign: "top",
        margin: 25,
        itemMarginTop: 0,
        symbolRadius: 0,
        symbolHeight: 10,
        symbolWidth: 10,
      },
      series: data.series,
    });
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LinechartComponent } from "./linechart/linechart.component";

import { HighchartsChartModule } from "highcharts-angular";
import { ChartModule } from 'angular-highcharts';
import { OeeTopcardComponent } from "./oee-topcard/oee-topcard.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { LineBarChartComponent } from "./line-bar-chart/line-bar-chart.component";
import { BarChartComponent } from "./bar-chart/bar-chart.component";
import { LossesCardComponent } from "./losses-card/losses-card.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ColumnChartComponent } from "./column-chart/column-chart.component";
import { SplineChartComponent } from "./spline-chart/spline-chart.component";
import { SolidGaugeChartComponent } from "./solid-gauge-chart/solid-gauge-chart.component";
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { SoildGuage2Component } from './soild-guage2/soild-guage2.component';
import { StockchartComponent } from './stockchart/stockchart.component';
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgxPaginationModule } from "ngx-pagination";

import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormsModule } from "@angular/forms";
import { BasicbarChartComponent } from './basicbar-chart/basicbar-chart.component';
import { BasiccolumnChartComponent } from './basiccolumn-chart/basiccolumn-chart.component';
import { BasicsplineChartComponent } from './basicspline-chart/basicspline-chart.component';
import { SingleSplineChartComponent } from './single-spline-chart/single-spline-chart.component';
import { TrippleSplineChartComponent } from './tripple-spline-chart/tripple-spline-chart.component';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { ScatterLineChartComponent } from './scatter-line-chart/scatter-line-chart.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import {MatMenuModule} from '@angular/material/menu';
import { MessageComponent } from './message/message.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownComponent } from './dropdown/dropdown.component';

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import {MatIconModule} from '@angular/material/icon';
import { GaugeChartComponent } from './gauge-chart/gauge-chart.component';


@NgModule({
  declarations: [
    LinechartComponent,
    OeeTopcardComponent,
    LineBarChartComponent,
    BarChartComponent,
    LossesCardComponent,
    ColumnChartComponent,
    SplineChartComponent,
    SolidGaugeChartComponent,
    PieChartComponent,
    SoildGuage2Component,
    StockchartComponent,
    DatePickerComponent,
    BasicbarChartComponent,
    BasiccolumnChartComponent,
    BasicsplineChartComponent,
    SingleSplineChartComponent,
    TrippleSplineChartComponent,
    DaterangepickerComponent,
    ScatterLineChartComponent,
    SpinnerComponent,
    DonutChartComponent,
    MessageComponent,
    PaginationComponent,
    DropdownComponent,
    GaugeChartComponent,
  ],
  imports: [
    NgxDaterangepickerMd.forRoot(),
    ChartModule,
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
  ],
  exports: [
    DatePickerComponent,
    PieChartComponent,
    LinechartComponent,
    OeeTopcardComponent,
    LineBarChartComponent,
    BarChartComponent,
    LossesCardComponent,
    ColumnChartComponent,
    SplineChartComponent,
    SolidGaugeChartComponent,
    SoildGuage2Component,
    StockchartComponent,
    BasicbarChartComponent,
    BasiccolumnChartComponent,
    BasicsplineChartComponent,
    SingleSplineChartComponent,
    TrippleSplineChartComponent,
    DaterangepickerComponent,
    ScatterLineChartComponent,
    SpinnerComponent,
    DonutChartComponent,
    MessageComponent,
    DropdownComponent,
    MatMenuModule,
    MatExpansionModule,
    PaginationComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    GaugeChartComponent
  ],
})
export class ComponentsModule {}

import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";

@Component({
  selector: "app-losses-card",
  templateUrl: "./losses-card.component.html",
  styleUrls: ["./losses-card.component.scss"],
})
export class LossesCardComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  areaChartOptions = {};
  totallosses: any;
  losses: any;
  panelOpenState= false;
  constructor() {}

  ngOnInit() {
    this.totallosses = 0;
    this.losses = this.data;
    this.losses.forEach((loss) => {
      let obj = {};
      let xaxis = [];
      let yaxis = [];
      this.totallosses += loss.lossDuration;
      loss.lossDetails.forEach((trend) => {
        trend.trend.forEach(t =>{
          
        })
        xaxis.push(trend.trend.timeframe);
        yaxis.push(parseInt(trend.value));
      });
      obj = {
        losstype: loss.lossType,
        xaxis: xaxis,
        yaxis: yaxis,
        trend: loss.trend.percentageChange,
      };
      loss["chart"] = this.areachart(obj);
    });
  }
  areachart(data) {
    return (this.areaChartOptions = {
      chart: {
        backgroundColor: "#37383c",
        type: "area",
        reflow:true
      },

      title: {
        text: "",
      },
      legend: {
        verticalAlign: "top",
        align: "start",

        // symbolRadius: 2,
        itemStyle: {
          color: "white",
          fontSize: "16px",
          fontWeight: "ligth",
          fillColor: "red",
        },
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          enabled: false,
        },
        lineWidth: 0,
        visible: false,
      },
      yAxis: {
        labels: {
          enabled: false,
          style: {
            color: "#bebfc0",
          },
        },
        title: {
          text: "",
        },
        gridLineWidth: 0,
      },
      tooltip: {
        pointFormat: `${data.losstype} - {point.y}`,
      },
      plotOptions: {
        area: {
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
            states: {
              hover: {
                enabled: true,
              },
            },
          },
          fillColor: "#65b2db",
        },
      },
      series: [
        {
          showInLegend: true,
          legendColor: "red",
          color: "#65b2db",
          name: `Trend: +${data.trend}%`,
          data: data.yaxis,
        },
      ],
    });
  }
}

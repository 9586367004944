export const themes = {
   // white
white: {
    '--primary-bg-color': '#ffff',
  '--secondary-bg-color': '#eeeeee',
  '--sidebar-bg-color':'#f8f8f8',
//   '--sidebar-bg-color':'#eeeeee',
  '--primary-text-color': '#595959',
  '--secondary-text-color':'#1D1D1F',
  '--graph-text-color': '#2c2d31',
  '--gridline-color' : '#45424528',
  '--primary-select-color':'#277ef3',
  '--border-color':'#CCCCCC',
  '--white-text-color':'#ffff',
  '--mat-expansion-panel':'#ffff',
  '--tabs-text':'#191919',
  '--primary-topbar-color': "#277ef3",

  },
  // black
  black :{
    '--primary-bg-color': '#2c2d31',
      '--secondary-bg-color': '#201e20',
      '--sidebar-bg-color':'#232324',
    //   '--sidebar-bg-color':'#201e20',
      '--primary-text-color': '#ffff',
      '--secondary-text-color' : '#a2a3b7',
      '--graph-text-color': '#ffff',
      '--gridline-color' : '#f2eef228',
      '--primary-select-color':'#277ef3',
      '--border-color':'#7a7979',
      '--white-text-color':"#ffff",
      '--mat-expansion-panel':'#454545',
      '--tabs-text':'#ffff',
      '--primary-topbar-color': "#201e20",

  }
  };
import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more.src";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge.src";
HC_solid_gauge(Highcharts);

@Component({
  selector: 'app-soild-guage2',
  templateUrl: './soild-guage2.component.html',
  styleUrls: ['./soild-guage2.component.scss']
})
export class SoildGuage2Component implements OnInit {

  @Input() data: any;
  highcharts = Highcharts;

  solidGaugeChartOptions = {};
  constructor() {}

  ngOnInit() {
    this.solidgauagechart(this.data);
  }
  solidgauagechart(data) {
    return (this.solidGaugeChartOptions = {
      chart: {
        type: "solidgauge",
        backgroundColor: "",
        marginTop: -10,
        reflow:true,
        style:{fontFamily: 'SF Pro Text'}
      },
      credits:{
        enabled:false,
        style:{
        color:'#999999',
        cursor:'pointer',
        fontSize:'9px',
        },
        text:'Highcharts.com'
        },
      title: {
        text: "",
      },
      subtitle: {
        align: "right",
        useHTML: true,
      },

      legend: {
        enabled: true,
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: 'var(--graph-text-color)',
        },
        labels: {
          enabled: true,
        },
        symbolHeight: 0.001,
        symbolWidth: 0.001,
        symbolRadius: 0.001,
      },
      pane: {
        // center: ["50%", "50%"],
        size: "90%",

        background: {
          backgroundColor: "var(--secondary-bg-color)",
          innerRadius: "80%",
          outerRadius: "90%",
          borderWidth: 0,
        },
      },

      yAxis: {
        labels: {
          enabled: true,
        },
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      tooltip: {
        time:{
          useUTC:false
        },
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          "%" +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor:"#eeeeee",
        borderColor:"#eeeeee"
      },
      plotOptions: {
        solidgauge: {
          innerRadius: "80%",
          outerRadius: "90%",
          linecap: "round",
          stickyTracking: false,
          rounded: false,
          dataLabels: {
            y: 50,
            borderWidth: 0,
            useHTML: false,
          },
        },
      },

      series: [
        {
          type: "solidgauge",
          showInLegend: false,
          name: "Health",
          rounded: true,
          dataLabels: {
            enabled: true,
            color:"var(--primary-text-color)",
            fontWeight:"lighter",
            style:{
              fontSize:25
            }
            // backgroundColor: "white",
          },
          data: [
            {
              color: parseFloat(data.y) > 70 ? "#6ac11c" : (parseFloat(data.y)  < 30 ? "#ff3917" : "#d9b006"),
              backgroundColor:"var(--secondary-bg-color)",
              radius: "90%",
              innerRadius: "80%",
              outerRadius: "90%",
              y: parseFloat(data.y) ,
              dataLabels: {
                y: -20,
                // format: "{y}%",
                formatter: function (){
                  return  `${parseFloat(data.y).toFixed(2)}%`
                },
                borderWidth: 0,
                style: {
                  fontSize: "30px",
                  color: "var(--graph-text-color)",
                  fontWeight:'lighter',
                },
              },
            },
          ],
        },
      ],
    });
  }
}
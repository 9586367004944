import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import * as highcharts from "highcharts";
import moment from "moment";
@Component({
  selector: "app-linechart",
  templateUrl: "./linechart.component.html",
  styleUrls: ["./linechart.component.scss"],
})
export class LinechartComponent implements OnInit {
  data = {};
  @Input("data")
  set in(val) {
    this.data = val;
    this.linechart(this.data);
  }
  highcharts = highcharts;
  lineChartOptions = {};
  shouldUpdateChanges = true;
  mr = 25;
  mt = 50;
  ml = 60;
  mb = 70;
  constructor() {}

  ngOnInit() {}

  linechart(data) {
    let self = this;
    return (this.lineChartOptions = {
      chart: {
        type: "line",
        marginRight: data.mr?data.mr:this.mr,
        marginTop: data.mt?data.mt:this.mt,
        marginLeft: data.ml?data.ml:this.ml,
        marginBottom: data.mb?data.mb:this.mb,
        backgroundColor: "transparent",
        padding: "10px",
        events: data.livedata ? data.livedata : null,
        // events: {
        //   load:{
        //     function (chartObj) {
        //       {

        //           var plotBand = chartObj.xAxis[0].plotLinesAndBands[1];

        //           if (plotBand.hidden) {
        //               plotBand.hidden = false;
        //               plotBand.svgElem.show();
        //           } else {
        //               plotBand.hidden = true;
        //               plotBand.svgElem.hide();
        //           }
        //       }

        //   }
        // }
        // },
        reflow: true,
        zoomType: "x",
        panning: true,
        panKey: "shift",
        style: { fontFamily: "SF Pro Text" },
      },
      credits: {
        enabled: false,
        style: {
          color: "#999999",
          cursor: "pointer",
          fontSize: "9px",
        },
        text: "Highcharts.com",
      },
      title: {
        text: "",
        align: "left",
        useHTML: false,
        style: {
          color: "var(--graph-text-color)",
          fontSize: "16px",
        },
      },
      subtitle: {
        text: "",
      },
      yAxis: data.y ? data.y : this.getyaxis(data),
      // accessibility:data.accessibility? data.accessibility:null,
      xAxis: {
        plotBands: data.plotBands != undefined ? data.plotBands : null,
        lineColor: "var(--graph-text-color)",
        lineWidth: 1,
        type: "datetime",
        events: {
          afterSetExtremes: function (event) {
            if (this.getExtremes().dataMin < event.min) {
            }
          },
        },
        labels: {
          style: {
            color: "var(--graph-text-color)",
            fontSize: 12,
          },
          step: 1,
          formatter: function () {
            if (data.format || data.format != undefined) {
              if(data.formatText){
              return moment(this.value).format(data.formatText);
              }else{
                return moment(this.value).format("HH:mm:ss");
              }
            } else {
              return this.value;
            }
          },
        },
        categories: data.xaxis ? data.xaxis : [],
        gridLineColor: "#bebfc0",
        gridLineWidth: 0,
        // tickInterval: data.tickinterval_x,
      },
      time: {
        useUTC: false,
      },
      legend: {
        align: "center",
        verticalAlign: data.legendposition ? data.legendposition : "bottom",
        layout: "horizontal",
        squareSymbol:false,
        symbolHeight: 20,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: "var(--graph-text-color)",
          fontWeight: "bolder",
          fontSize: 13,
        },
        y: 5,
      },
      plotOptions: {
        line: {
          threshold: data.threshold ? data.threshold : 0,
        },
        series: {
          //   zones: [{
          //     value: 30, // Values up to 0 (not including) ...
          //     color: 'blue' // ... have the color blue
          // },{
          //     value: 55, // Values up to 10 (not including) ...
          //     color: 'green' // ... have the color orange
          // },{
          //     color: 'red' // Values from 10 (including) and up have the color red
          // }],
          turboThreshold: 4000,
          label: {
            connectorAllowed: false,
          },
        },
      },
      tooltip: {
        time: {
          useUTC: false,
        },
        xDateFormat: "%d-%m-%y, %H:%M:%S",
        headerFormat:
          '<span style="font-size:14px; font-weight:bolder">' +
          data.title+
          "<br/> </span>" +
          "<span style='font-size:14px;'>at {point.key}</span><table>",
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:1">{series.name}:</td>' +
          '<td style="margin-left:2px"><b style="margin-left:2px">{point.y:.2f} {series.options.custom.unit}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor: "#eeeeee",
        borderColor: "#eeeeee",

      },
      series: data.yaxis,
    });
  }
  getUnit(data){
    console.log(data)
  }
  getyaxis(data) {
    let obj = {
      plotLines: data.threshold ? data.threshold : null,
      gridLineColor: "var(--gridline-color)",
      lineWidth: 0,
      min:data.min_y,
      lineColor: "var(--graph-text-color)",
      // tickInterval: data.tickinterval_y,
      endOnTick: false,
      title: {
        text: `${data.label_y}`,
        style: {
          color: "var(--graph-text-color)",
          fontSize: 14,
        },
      },
      labels: {
        style: {
          color: "var(--graph-text-color)",
          fontSize: 12,
        },
        formatter: function () {
          if (!this.isFirst) {
            return this.value;
          }
        },
      },
    };
    return obj;
  }
}

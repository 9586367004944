import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import moment, { Moment } from "moment";
import { DataService } from '../services/data.service';
@Component({
  selector: 'app-sub-navbar',
  templateUrl: './sub-navbar.component.html',
  styleUrls: ['./sub-navbar.component.scss']
})
export class SubNavbarComponent implements OnInit {
  selected: { start: Moment; end: Moment };
  maxDate: moment.Moment;
  localdata = {
    displayFormat: "MM/DD/YYYY",
  };
  user = localStorage.getItem('user');
  shouldDisplayIndicators = true;
  rangesnew = {
    Today: [moment, moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  id: any;
  node;
  selectedIndex = 0;
  options: any = {
    alwaysShowCalendars: true,
  };
  startDate: any = moment().subtract(45, "days").toDate();
  endDate: Date = moment().endOf("month").toDate();
  dataplaceholder =
    "" +
    moment(this.startDate).format("DD/MM/YYYY") +
    "-" +
    moment(this.endDate).format("DD/MM/YYYY");
  isactive: boolean = true;
  selectedTab = 0;
  index_subscribe: any;
  constructor(private router: Router, private service: DataService) { }
  ngOnInit() {
    if (localStorage.getItem("selectedSubModule"))
    {
      this.selectedIndex = JSON.parse(localStorage.getItem("selectedSubModule"));
    }
  }
  change(e) { }
  selecttab(e) {
    localStorage.setItem("selectedSubModule", e.index);
    if (e.index == 0)
    {
      this.router.navigate(['/common/predictive-maintenance/pm/overview']);
    }
    else if (e.index == 3)
    {
      this.router.navigate(['/common/alerts/']);
    }
    else if (e.index == 1)
    {
      this.router.navigate(['/common/monitor/']);
    }
    else if (e.index == 2)
    {
      this.router.navigate(['/common/compare/']);
    }
    else if (e.index == 4)
    {
      this.router.navigate(['/common/downtime-analytics/']);
    }
    else if (e.index == 5)
    {
      this.router.navigate(['/common/reports/']);
    }
    else if (e.index == 6)
    {
      this.router.navigate(['/common/maintenance/']);
    }
    else if (e.index == 9)
    {
      if(this.user!='analytics@assethealth.com'){
        this.router.navigate(["/common/advanced_analytics/"]);
      }
      else{
      this.router.navigate(['/common/furnace-cbm/']);
      }
    }
    else if (e.index == 7)
    {
      this.router.navigate(['/common/secc/']);
    }
    else if (e.index == 8)
    {
      this.router.navigate(['/common/quality/']);
    }
    else if(this.user!='analytics@assethealth.com'){

    }

    // else if (e.index == 7)
    // {
    //   this.router.navigate(['/common/secc/']);
    // }
    // if(this.index_subscribe != null){
    //   this.index_subscribe.unsubscribe();
    // }
    // let index = typeof e.tab.textLabel == 'string' ?  Number(e.tab.textLabel) : 0;
    // localStorage.setItem("tab_index", JSON.stringify(index));
    // this.selectedTab= index;
    // if(this.node.level =="asset"){
    //   this.shouldDisplayIndicators = true;
    // }else{
    //   this.shouldDisplayIndicators = false;
    // }
    //     if(index == 0){
    //       this.service.activate_navbars({sidebar:true, tabbar:true});
    //       this.shouldDisplayIndicators = false;
    //       this.service.activeSubModule("overview");
    //       if(this.node.level == "fleet"){
    //         this.router.navigate(['/common/predictive-maintenance/pm/fleet-level-overview']);
    //       }else if (this.node.level == 'plant'){
    //      this.router.navigate(['/common/predictive-maintenance/pm/plant-level-overview']);
    //       }else if (this.node.level == 'unit'){
    //         this.router.navigate(['/common/predictive-maintenance/pm/line-level-overview']);
    //       }else if (this.node.level == 'asset'){
    //         this.shouldDisplayIndicators = true;
    //         if (this.node.name == "Induction Heater") {
    //           this.router.navigateByUrl(
    //             "/common/predictive-maintenance/pm/asset-level-overview"
    //           );
    //         } else if (this.node.name == "Rough Press" || this.node.name == "Finish Press") {
    //           this.router.navigate(
    //             ["/common/predictive-maintenance/pm/press-asset-overview", this.node.id]
    //           );
    //         }else if(this.node.name == "Dilute System"){
    //           this.router.navigateByUrl(
    //             "/common/predictive-maintenance/pm/dilute-system"
    //           );
    //         }else if(this.node.name == "Slow cooling conveyer"){
    //           this.router.navigateByUrl(
    //             "/common/predictive-maintenance/pm/cooling-conveyor"
    //           );
    //         }
    //       }
    //     }else if(index == 1){
    //       this.service.activate_navbars({sidebar:true, tabbar:true});
    //       if(this.node.level == "asset"){
    //         this.shouldDisplayIndicators = true;
    //         this.service.activeSubModule("indicators");
    //         this.router.navigate(['/common/predictive-maintenance/pm/indicators']);
    //       }else{
    //         this.shouldDisplayIndicators =false;
    //         localStorage.setItem("tab_index", JSON.stringify(index));
    //         this.service.activeSubModule("overview");
    //        if(this.node.level == "fleet"){
    //         this.router.navigate(['/common/predictive-maintenance/pm/fleet-level-overview']);
    //        }else if (this.node.level == 'plant'){
    //         this.router.navigate(['/common/predictive-maintenance/pm/plant-level-overview']);
    //        }else if (this.node.level == 'unit'){
    //         this.router.navigate(['/common/predictive-maintenance/pm/line-level-overview']);
    //        }else{
    //         this.router.navigate(['/common/predictive-maintenance/pm/fleet-level-overview']);
    //        }
    //       }
    //     }else if(index == 2){
    //       this.service.activate_navbars({sidebar:false, tabbar:true});
    //       this.service.activeSubModule("alerts");
    //       this.router.navigate(['/common/predictive-maintenance/pm/alerts/alert']);
    //     }else if(index == 3){
    //       this.service.activate_navbars({sidebar:false, tabbar:true});
    //       this.service.activeSubModule("reports");
    //       this.router.navigate(['/common/reports/']);
    //     }
  }
}


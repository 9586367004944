import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge";
HC_solid_gauge(Highcharts);
@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {
  defaultData = { Value: 0 };
  height:any
@Input('ChartData')
set in(val:any){
  if (val) {
    val.Value =Number(Number(parseFloat(val.Value).toFixed(2)));
    this.height=val.height
    if(val.type=='solidgauge'){
    this.solidgauagechart(val)
    }else{
      this.plotGraph(val);
    }

  } else {
    this.plotGraph(this.defaultData);
  }
}
  constructor() { }
  highcharts = Highcharts;

  solidGaugeChartOptions = {};
  ngOnInit(): void {
  }
  plotGraph(data:any) {
    return (this.solidGaugeChartOptions = {
      chart: {
        type:"gauge",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        spacing: 0,
        backgroundColor: "transparent",
        marginBottom:data.mb?data.mb:10,
        marginTop:data.mt?data.mt:10,
        marginLeft:data.ml?data.ml:10,
        marginRight:data.mr?data.mr:10
      },

      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: data.startAngle?data.startAngle:-180,
        endAngle: data.endAngle?data.endAngle:180,
        size: data.chartSize?data.chartSize:"90%",
        // outerRadius: "100%",
        // innerRadius: "60%",
        background: [
        {
          borderWidth: 1,
          borderRadius: 6,
          borderColor: '#EEEEEE',
        },
        {
          backgroundColor:' #FFFFFF',
          borderColor: '#EEEEEE',
        },
        ],
      },


      // the value axis
      yAxis: {
        min: 0,
        max: 100,
        lineColor: null,
        minorTickInterval: "auto",
        minorTickWidth: 0,
        minorTickLength: 10,
        minorTickPosition: "inside",
        minorTickColor: "#fff",
        tickPixelInterval: 30,
        tickWidth: 0,
        tickPosition: "outside",
        tickLength: 10,
        tickColor: "#666",
        startOnTick: false,
        minPadding: 100,
        labels: {
          step: data.dataLabels.step?data.dataLabels.step:2,
          rotation: "auto",
          overflow: "justify",
          distance: -20,
          style:data.dataLabels.style?data.dataLabels.style: {
            color: "#fff",
            fontWeight: "500",
            marginBottom: 100,
            fontSize: 13,
          },
        },
        title: {
          text: "",
        },
        plotBands: [
          {
            from: 65,
            to: 100,
            innerRadius: "70%",
            color: "#467BC9", // 65-100
          },
          {
            from: 65,
            to: 85,
            innerRadius: "70%",
            color: "#A8CBFF", // 65-85
          },
          {
            from: 0,
            to: 65,
            innerRadius: "70%",
            color: "#5599FF", // 0-65
          },
        ],
      },

      tooltip: {
        time: {
          useUTC: false,
        },
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          "%" +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor: "#eeeeee",
        borderColor: "#eeeeee",
      },
      plotOptions: {
        solidgauge: {
          pointStart: 30,
          innerRadius: "60%",
          rounded: true,
          shadow: {
            borderRadius: 50,
            color: '#d8d8d8',
            offsetX: 0,
            offsetY: 0,
            opacity: 1,
            width: 6
        },
        },
      },
      series: [
        {
          name: data.name || "Value",
          data: [data.Value],
          tooltip: {
            valueSuffix: "",
          },
          dataLabels: {
            enabled: true,
            color: "black",
            fontWeight: "lighter",
            format: `${data.Value} %`,
            style: {
              fontSize: 15,
            },
            // backgroundColor: "white",
          },
          dial: {
            radius: '60%',
            backgroundColor: '#D8D8D8',
            baseWidth: 12,
            baseLength: '0%',
            rearLength: '0%'
        },
        pivot: {
            backgroundColor: '#D8D8D8',
            radius: 6
        }
        },
      ],

    });
  }
  solidgauagechart(data) {
    return (this.solidGaugeChartOptions = {
      chart: {
        type: "solidgauge",
        backgroundColor: "",
        reflow: true,
        style: { fontFamily: "sfpro-r " },
        marginBottom: data.mb ? data.mb : 10,
        marginTop: data.mt ? data.mt : 10,
        marginLeft: data.ml ? data.ml : 10,
        marginRight: data.mr ? data.mr : 10,
      },
      credits: {
        enabled: false,
        style: {
          color: "#999999",
          cursor: "pointer",
          fontSize: "9px",
        },
        text: "Highcharts.com",
      },
      title: {
        text: "",
      },
      subtitle: {
        align: "right",
        useHTML: true,
      },

      legend: {
        enabled: false,
        align: "center",
        verticalAlign: "bottom",
        itemStyle: {
          color: "var(--graph-text-color)",
          fontFamily: "SF Pro Text",
        },
        labels: {
          enabled: true,
        },
        symbolHeight: 0.001,
        symbolWidth: 0.001,
        symbolRadius: 0.001,
      },
      pane: {
        // center: ["50%", "50%"],
        size: data.chartSize ? data.chartSize : "90%",

        background: {
          backgroundColor: "#1FCB4F",
          innerRadius: data.innerRadius ? data.innerRadius : "50%",
          outerRadius: data.outerRadius ? data.outerRadius : "100%",
          borderWidth: 0,
          shape: "arc",
        },
      },

      yAxis: {
        labels: {
          enabled: true,
        },
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      tooltip: {
        enabled:
          data.tooltip && data.tooltip.enable ? data.tooltip.enable : false,
        time: {
          useUTC: false,
        },
        pointFormat:
          '<tr style="font-size:14px;z-index:-1"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b> Age {point.y:.2f}  ' +
          "%" +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor: "#eeeeee",
        borderColor: "#eeeeee",
      },
      plotOptions: {
        solidgauge: {
          innerRadius: data.innerRadius ? data.innerRadius : "50%",
          outerRadius: data.outerRadius ? data.outerRadius : "100%",
          linecap: "round",
          stickyTracking: false,
          rounded: false,
          shape: "arc",
          // series: {
          //     dataLabels: {
          //         enabled: true
          //     }
          // }
          dataLabels: {
            enabled: true,
            y: 50,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },

      series: [
        {
          type: "solidgauge",
          showInLegend: false,
          name: data.name ? data.name : "",
          rounded: false,
          background: "red",
          dataLabels: {
            enabled: true,
            color: "#595959",
            fontWeight: "bold",
            fontFamily: "SF Pro Text",
            style: {
              fontSize: 25,
            },
            // backgroundColor: "red",
          },
          data: [
            {
              color: data.plotcolor ? data.plotcolor : "#2a7cf5",
              backgroundColor: "#2a7cf6",
              radius: data.radius ? data.radius : "100%",
              y: parseFloat(data.Value),
              dataLabels: {
                y: -35,
                useHTML: true,
                formatter: function () {
                  return data.showLabel
                    ? `<div style="font-weight:200;color:#757778" > Lifecycle</div>
                  <div style="display:flex; justify-content:center; margin:10px;font-size:15px">${parseFloat(
                    data.life_cycle
                  )} </div>
                  <div style="display:flex; justify-content:center ;font-weight:200; color:#757778">days</div>
                              `
                    : ``;
                },
                borderWidth: 0,
                style: {
                  fontSize: "13px",
                  color: "var(--graph-text-color)",
                },
              },
            },
          ],
        },
      ],
    });
  }

}

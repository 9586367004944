// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  /********************las_Prod Config************************* */
  // firebase: {
  //   apiKey: "AIzaSyAQtYLN-g7ZZi4rLa6OabUV3BZ34ZgLd04",
  //   authDomain: "las-prod-1.firebaseapp.com",
  //   databaseURL: "https://las-prod-1.firebaseio.com",
  //   projectId: "las-prod-1",
  //   storageBucket: "las-prod-1.appspot.com",
  //   messagingSenderId: "896074191368",
  // },
  /*********************SONA BLW Config************************************* */
  firebase:{
    apiKey: "AIzaSyCSNYM5Na2fmCWv2LQLLtWHJujWx8BiMsk",
    authDomain: "sona-blw.firebaseapp.com",
    projectId: "sona-blw",
    storageBucket: "sona-blw.appspot.com",
    messagingSenderId: "560191140597",
    appId: "1:560191140597:web:db643c8b3e7aafda034bbc",
    measurementId: "G-6HQJ82RRT1"
  },
  SOCKET_ENDPOINT: "http://localhost:9330",
  // API_ENDPOINT: "http://192.168.1.131:9330/"
  API_ENDPOINT: "http://localhost:9330/",
  SERVICE_CALL: 'fs',
  // SERVICE_CALL: 'http',
  ASSET_HEALTH_COUNT:100,
  test_trackingId:"G-P87DWTY6NT",
  prod_tracking:'G-9Q299SF3L1',
  version:'2.1.02.24',
  authencationApi:'https://us-central1-blp-shared.cloudfunctions.net/blp_user_authentication',
  authenticationKey : "237f306841bd23a418878792252ff6c8",
  firebasetokenKey : 'lkjhgsxfttnhtsx33dcvqzzs8765trzxcv',
  localToken: '30m05a20y23@13:13'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

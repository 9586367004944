import { Component, Input, OnInit } from '@angular/core';
import * as highcharts from "highcharts";
import { Chart } from 'angular-highcharts';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  chartOptions :any= {}
  @Input('data')
  set in(val:any){
    this.plotChart(val)
  }
  highcharts:any = highcharts
  constructor() { }
  donutChart: Chart;
  plotChart(data:any) {
    return this.chartOptions =
      {
      chart: {
        plotBorderWidth: 0,
        plotShadow: false,
        // marginBottom:70,
        marginTop:-10,
        marginRight:20,
        backgroundColor:"transparent"
      },
      tooltip: {
        pointFormat: '{point.description}: <b>{point.y:.2f} {point.tooltip.valueSuffix}</b>'
      },
      credits: {
        enabled: false
      },
       title: {
        text: '',
        style:{fontSize:'16px', fontFamily: "SF Pro Text"}
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // connectorShape: 'fixedOffset',
            // alignTo: 'connectors',
            alignTo: 'alignto',
            connectorShape: 'crookedLine',
            crookDistance: '70%',
            format: '{point.description}',

          },
          center: ["50%", "50%"],
          startAngle: -90,
          endAngle: -180,
          size: '65%',
          showInLegend: true
        }

      },

      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        // symbolPadding: 7,
        symbolWidth: 7,
        symbolHeight:7,
        squareSymbol:true,
        symbolRadius: 0,
        useHTML:true,
        x: 0,
        y: 21,
        padding:0,
        labelFormatter: function () {
          return `<div style="font-size:12px;color:#2C3A43;align-items:center;">
           <div >${this['name']}</div> <div>${this['description']}<div/></div>`

      },
        },
      series: [
        {
          name: data.title?data.title:"",
          dataLabels:{enabled: true, color:"#2C3A43",style:{fontSize:'11px', fontFamily: "SF Pro Text"},distance:15},
          data: data.data,
          type: 'pie',
          showInLegend:false,
          innerSize: '50%',
        }]
    }
  }

  ngOnInit(): void {
  }

}

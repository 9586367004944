import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment, { MomentInput } from 'moment';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent implements OnInit {

    
  @Output() selectedDates = new EventEmitter()
  //@Input() InitialDates:any
  _InitialDates:any;
  placeholder: string;
  @Input() opens = 'center';
  @Input() InitialDates:any
  @Input() isSingle = false;
  // set in(val: any) {
  //   this._InitialDates = val;
  //   this.startDate = this._InitialDates.startDate;
  //   this.endDate = this._InitialDates.endDate;
  //   // this.daterangeValue = document.getElementById('daterange');
  //   this.data_frequency = `${moment(this.startDate).format("DD/MM/YYYY")} - ${moment(this.endDate).format("DD/MM/YYYY")}`;
  //   // if (this.daterangeValue) {
  //   //   this.daterangeValue.value = this.data_frequency;
  //   // }
  // }
  dateoptions: any = {
    alwaysShowCalendars: true,
  };
  maxDate: any = moment();
  minDate: any;
  localdata = {
    displayFormat: "DD/MM/YYYY",
  };
  daterangeValue: any = document.getElementById('daterange');
  data_frequency: string="";
  endDate: MomentInput;
  startDate: MomentInput;
  
  @ViewChild('daterange', { static: false }) selectedDateRange:any

  constructor() { 
    this.minDate = moment().subtract(3, 'days').toDate();
  }

  ngOnInit() {
    if(this._InitialDates){
      this.startDate = this._InitialDates.startDate
      this.endDate = this._InitialDates.endDate
    }
    if(!this.isSingle){
      this.data_frequency = `${moment(this.startDate).format("DD/MM/YYYY")} - ${moment(this.endDate).format("DD/MM/YYYY")}`;
      this.placeholder = this.data_frequency;
    }else{
      this.data_frequency = `${moment(this.startDate).format("DD/MM/YYYY")}`;
      this.placeholder = this.data_frequency;
    }
  }

  customchangeDuration(e){
    this.selectedDates.emit(e)
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(change: SimpleChanges) {
    if(change.InitialDates && change.InitialDates.currentValue) {
      this._InitialDates = change.InitialDates.currentValue;
      this.startDate = this._InitialDates.startDate;
      this.endDate = this._InitialDates.endDate;
      this.data_frequency = `${moment(this.startDate).format("DD/MM/YYYY")} - ${moment(this.endDate).format("DD/MM/YYYY")}`;
   
    }
  }

}

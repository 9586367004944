import { Component, Input, OnChanges, OnInit } from "@angular/core";
import * as highcharts from "highcharts";
import moment from "moment";

@Component({
  selector: "app-spline-chart",
  templateUrl: "./spline-chart.component.html",
  styleUrls: ["./spline-chart.component.scss"],
})
export class SplineChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  splinChartOptions;
  shouldUpdateChanges = true;
  constructor() {}
  ngOnInit() {
    this.splinechart(this.data[0]);
  }
  splinechart(data) {
    return (this.splinChartOptions = {
      time:{
        useUTC:false
      },
      chart: {
        type: "spline",
        backgroundColor: "transparent",
        marginRight: data.mr,
        marginTop: data.mt,
        marginLeft: data.ml,
        marginBottom: data.mb,
        events: data.livedata? data.livedata : null,
        reflow:true,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        style:{fontFamily: 'SF Pro Text'}
      },
      credits:{
        enabled:false,
        style:{
        color:'#999999',
        cursor:'pointer',
        fontSize:'9px',
        },
        text:'Highcharts.com'
        },
      title: {
        text: "",
        align: "left",
        style: { color: 'var(--graph-text-color)', fontSize: "16px", fontWeight: "lighter" },
        useHTML: false,
      },
      subtitle: {
        text: "",
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        squareSymbol: true,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: 'var(--graph-text-color)',
          fontWeight: "ligther",
          fontSize: 13,
        },
        y:10
      },
      accessibility:data.accessibility? data.accessibility:null,
      xAxis: {
        plotBands: data.plotBands != undefined? data.plotBands: null,
        lineWidth: 1,
        lineColor:"var(--graph-text-color)",
        type: 'datetime',
        labels: {
          style: {
            color: 'var(--graph-text-color)',
            fontSize:12
          },
          title: {
            style: {
              color: 'var(--graph-text-color)',
            },
            text: data.label_x ? data.label_x : "",
          },
          step:1,
          formatter: function () {
            if (data.format || data.format != undefined) {
              return moment(this.value).format("HH:mm:ss");
            } else {
              return this.value;
            }
          },
        },
        categories: data.xaxis,
        // tickInterval: 1000 * 60 * 5,
        time: {
          useUTC: false
      },
      },
      yAxis: {
          lineColor:"var(--graph-text-color)",
          gridLineColor: "var(--gridline-color)",
        lineWidth: 0,
        // tickInterval: data.tickinterval_y,
        plotLines:data.threshold ? data.threshold : null,
        endOnTick: false,
        labels: {
          style: {
            color: 'var(--graph-text-color)',
            // fontSize:data.y_label_size ? data.y_label_size : 11
            fontSize:12
          },
          formatter: function() {
            if (!this.isFirst) {
              return this.value
            }
          }
        },
        title: {
          style: {
            color: 'var(--graph-text-color)',
            fontSize: 14
          },
          text: data.label_y,
        },
      },
      tooltip: {
        time:{
          useUTC:false
        },
        xDateFormat: '%d-%m-%y, %H:%M:%S',
        headerFormat:
        '<span style="font-size:14px; font-weight:bolder">' +
        data.label_y + "<br/> </span>" +
        "<span style='font-size:14px;'>at {point.key}</span><table>",
        pointFormat:
          '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} ' +
          data.unit +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor:"#eeeeee",
        borderColor:"#eeeeee"
      },
      plotOptions: {
        series:{
          turboThreshold:4000,
          // pointInterval :1000 * 60 *5
        }
      },
      series: data.yaxis,
    });
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { DataService } from '../services/data.service';
import { FirestoreService } from '../services/firestore.service';
import moment from 'moment';
import * as _ from 'lodash';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {
  formData:any= new FormData();
  uploadevent: any;
  isFileChoosen: boolean = false;
  fileList: any[];
  event: any;
  choosenfileName = ""
  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  filename: string;
  extension: any;
  file: any;
  userRole: string;
  selectedIndex: number = 0;
  filteredFileList = [];
  reportTypes = [
    {
      "title": "PM/CM Reports",
      "role": "",
      "sort": "0",
      "category": "PM/CM"
    },
    {
      "title": "Analytic Reports",
      "role": "analytics",
      "sort": "1",
      "category": "analytics"
    },
    {
      "title": "RUL Reports",
      "role": "rul",
      "sort": "2",
      "category": "rul"
    }
  ];
  uploadSectionTitle: string = "Please upload your PM/CM reports here";
  dialogInfo: any = {
    show: false
  }
  rulReportData: any[] = [];
  newReliabilityDate: any;
  newZeroReliabilityDate: any;
  finalRulReportData: any[] = [];
  csvoptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  constructor(private router: Router, public service: DataService, private _snackBar: MatSnackBar, private db: FirestoreService, private fs: AngularFirestore) { }

  ngOnInit() {
    this.getfiles();
    this.getRulReportData();
    this.userRole = localStorage.getItem("report_user");
    if (this.userRole == "analytics")
    {
      this.uploadSectionTitle = "Please upload your analytic reports here";
    }
  }
  back() {
    this.router.navigate(['/common/predictive-maintenance/pm/fleet-level-overview']);
    this.service.activate_navbars({ sidebar: true, tabbar: false });
  }
  getfiles() {
    this.fileList = [];
    this.service.activateloader(true)
    this.db.getFileList().subscribe(data => {
      this.service.activateloader(false)
      data.forEach(element => {
        this.fileList.push(element.data())
      });
      this.fileList.forEach((file: any) => {
        let name = file.fileUrl.substring(file.fileUrl.lastIndexOf('/') + 1, file.fileUrl.length);
        let ext = name.substring(name.lastIndexOf('.') + 1, name.length);
        file.name = name;
        file.ext = ext;
      });
      let tabIndex = 0;
      let filterType = this.reportTypes.filter((report) => {
        return report.role == this.userRole
      });
      if (filterType.length > 0)
      {
        tabIndex = parseInt(filterType[0].sort);
      }
      this.selecttab({ index: tabIndex });
    });

  }
  downloadfile(file:any){
    this.db.getFileUrlfromFirestorage(file['fileUrl'],2).catch((err=>console.log(err)))
    .then((response:any)=>{
      window.open(response['url'])
    })
    }
  selecttab(e) {
    this.selectedIndex = e.index
    this.filteredFileList = [];
    let filterType = this.reportTypes.filter((report) => {
      return report.sort == e.index.toString();
    });
    if (filterType.length > 0)
    {
      if (filterType[0].role == "")
      {
        this.filteredFileList = this.fileList.filter((data) => {
          return data.role != "analytics";
        })
      } else
      {
        this.filteredFileList = this.fileList.filter((data) => {
          return data.role == filterType[0].role;
        })
      }
    }
  }

  chooseFile(e) {
    this.isFileChoosen = true;
    this.file = e.target.files[0];
    this.extension = this.file.name.substring(this.file.name.lastIndexOf('.') + 1, this.file.name.length);
    this.filename = new Date().getTime() + "." + this.extension;
  }
  uploadFile() {
    if (this.isFileChoosen && this.choosenfileName != '')
    {
      let formData=new FormData
      this.service.activateloader(true);
      this.filename = this.choosenfileName + "_" + this.filename;
      formData.append('file',this.file,this.filename);
      formData.append('role',this.userRole);

      let filepath=`SonaOEEUploadedReports/${this.filename}`
      this.service.activateloader(true)
        this.db.uploadFileintoFirebaseStorage(filepath,this.file).then((response:any)=>{
          this.service.activateloader(false)
          if(response['status']=='success'){
            let obj:any={ }
            obj['customerId']='SONA'
            obj['fileUrl']=`https://storage.cloud.google.com/sona-blw.appspot.com/${filepath}`
            obj['role']=this.userRole
            obj['timestamp']=moment().toDate()
            this.service.activateloader(true)
            this.db.uploadReportFile(obj).then((res:any)=>{
              this.service.activateloader(false)
              this.getfiles();
              this.service.activateloader(false);
              this._snackBar.open("File Uploaded Successfully", "", {
                      duration: 2000,
                      panelClass: ['green-snackbar'],
                      horizontalPosition: "end",
                      verticalPosition: "bottom",
                    });
            }).catch((err)=>{
              this.service.activateloader(false);
              console.log(err)
            })
          }
        }).catch((err)=>{
          this.service.activateloader(false);
          this._snackBar.open("File Upload Failed", "", {
            duration: 2000,
            panelClass: ['red-snackbar'],
            horizontalPosition: "end",
            verticalPosition: "bottom",
          });
          console.log(err)
        })
      // this.db.uploadFile(formData).subscribe(data => {
      //   if (data != undefined)
      //   {
      //     this.service.activateloader(false);
      //     this.fileInput.nativeElement.value = "";
      //     this.choosenfileName = "";
      //     this._snackBar.open("File Uploaded Successfully", "", {
      //       duration: 2000,
      //       panelClass: ['blue-snackbar'],
      //       horizontalPosition: "end",
      //       verticalPosition: "bottom",
      //     });
      //     this.getfiles();
      //   } else
      //   {
      //     this.service.activateloader(false);
      //     this._snackBar.open("File Uploaded Failed", "", {
      //       duration: 2000,
      //       panelClass: ['red-snackbar'],
      //       horizontalPosition: "end",
      //       verticalPosition: "bottom",
      //     });
      //   }
      // },(err) =>{
      //   console.log(err)
      //   this.service.activateloader(false);
      // });
    } else
    {
      this._snackBar.open("All fields are required", "", {
        duration: 2000,
        panelClass: ['red-snackbar'],
        horizontalPosition: "end",
        verticalPosition: "bottom",
      });
    }
  }

  dialogAction(action, data) {
    if (action == "open")
    {
      this.dialogInfo["title"] = "Are you sure want to delete?";
      this.dialogInfo["doBtnLabel"] = "Yes";
      this.dialogInfo["dontBtnLabel"] = "No";
      this.dialogInfo["data"] = data;
      this.dialogInfo["show"] = true;
    }
    else if (action == "do")
    {
      this.delete(this.dialogInfo["data"]);
      setTimeout(() => {
        this.dialogInfo = {
          show: false
        }
      }, 10);

    } else if (action == "dont")
    {
      this.dialogInfo = {
        show: false
      }
    }
  }

  delete(data) {
    let id;
    if (data != undefined)
    {
      this.fs.collection("/Asset_OEE/SONA/Reports", ref => ref.where('fileUrl', "==", data.fileUrl)).snapshotChanges()
        .subscribe(element => {
          id = element[0].payload.doc.id

          if (id != undefined)
          {

            this.fs.collection("/Asset_OEE/SONA/Reports").doc(id).delete().then(() => {
              let file=data.fileUrl.split('/')
              file=file.slice(((file.length)-2)).join('/')
              this.service.activateloader(true)
              this.db.deleteFileinFirebaseStorage(file).then((response)=>{
                this.service.activateloader(false)
                this._snackBar.open("File Deleted Successfully", "", {
                  duration: 2000,
                  panelClass: ['green-snackbar'],
                  horizontalPosition: "end",
                  verticalPosition: "bottom",
                });
                this.getfiles();
              }).catch(err=>console.log(err))

            }).catch(() => {
              this._snackBar.open("File Deleted Failed", "", {
                duration: 2000,
                panelClass: ['red-snackbar'],
                horizontalPosition: "end",
                verticalPosition: "bottom",
              });
            })
          } else
          {
            this._snackBar.open("File Deleted Failed", "", {
              duration: 2000,
              panelClass: ['red-snackbar'],
              horizontalPosition: "end",
              verticalPosition: "bottom",
            });
          }
        })
    }
  }

  getRulReportData(){
    this.rulReportData = [];
    this.service.activateloader(true)
    this.db.getRulReportData().subscribe((result: any)=>{
      this.service.activateloader(false)
      result.forEach((res: any)=> {
        let reliabilityDate = moment(res['liner change date'], 'YYYY-MM-DD');
        let addReliabilityDate = res.Threshold % 1 > 0.5 ? Math.ceil(res.Threshold) : Math.floor(res.Threshold);
        res['newReliabilityDate'] = reliabilityDate.add(addReliabilityDate, 'days').format('DD-MM-yyyy');

        let zeroReliabilityDate = moment(res['liner change date'], 'YYYY-MM-DD');
        let addZeroReliabilityDate = res.Zero_reliability % 1 > 0.5 ? Math.ceil(res.Zero_reliability) : Math.floor(res.Zero_reliability);
        res['newZeroReliabilityDate'] = zeroReliabilityDate.add(addZeroReliabilityDate, 'days').format('DD-MM-yyyy');
        this.rulReportData.push(res)

      })
      let groupedData: any = _.groupBy(this.rulReportData, (d: any)=> {
        return moment(d['liner change date']);
      })
      for(let key in groupedData){
        groupedData[key]= _.orderBy(groupedData[key], (d: any)=> {
          return d.Date
        }, ['desc'])
        this.finalRulReportData.push(groupedData[key][0]);
        this.finalRulReportData = _.orderBy(this.finalRulReportData, (date: any)=>{
          return date['liner change date']
        }, ['desc'])
      }
    })
  }
  download(){
    let array: any = { csv: [], title:'RUL Report', filename:''};
    array.filename = array.title;
    let final:any = []
    this.finalRulReportData.forEach(d =>{
      let obj = {}
      obj['Equipment Name'] = d.asset
      obj['Line'] = 'Line 2'
      obj['Liner Changed Date'] = moment(d['liner change date'], 'YYYY-MM-DD').format('DD-MMM-yyyy')
      let reliabilityDate = moment(d['liner change date'], 'YYYY-MM-DD');
      let addReliabilityDate = d.Threshold;
      obj['20% Reliability Predicted Date'] = reliabilityDate.add(addReliabilityDate, 'days').format('DD-MMM-yyyy');
      let zeroReliabilityDate = moment(d['liner change date'], 'YYYY-MM-DD');
      let addZeroReliabilityDate = d.Zero_reliability;
      obj['0% Reliability Predicted Date'] = zeroReliabilityDate.add(addZeroReliabilityDate, 'days').format('DD-MMM-yyyy');
      obj['Reliability'] = `${((d.current_reliability) * 100 ).toFixed(2)} % on ${moment(d.Date.seconds * 1000).format('DD-MMM-yyyy')}`
      final.push(obj)
    })
    array.csv = final;
    this.csvexport(array);
  }
  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }


}

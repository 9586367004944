import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {env} from 'process';
import {environment} from 'src/environments/environment';
import {AngularFirestore} from '@angular/fire/firestore';
import {element, promise} from 'protractor';
import * as _ from 'lodash';
import {defer, from, Observable, of} from 'rxjs';
import moment from 'moment';
import {resolve} from 'url';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import { UtilService } from './util.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: "root",
})
export class FirestoreService {
  httpHeaders: any;
  domainUrl: any = "https://sona-dserver-v1-xm3dgannva-uc.a.run.app";
  // domainUrl:any='https://sona-blw-dserver-test-jwt-xm3dgannva-uc.a.run.app'
  // domainUrl:any='http://192.168.1.199:8000'
  // domain = "https://datastore.ai-works.in:9331"
  domain = "https://datastore.orionintelligentenergy.com/";
  constructor(
    private db: AngularFirestore,
    private http: HttpClient,
    private firebaseStorage: AngularFireStorage,
    private auth: AngularFireAuth,
    private router: Router,
    private util: UtilService
  ) {
    // this.httpHeaders = new HttpHeaders();
    // this.httpHeaders.set("Content-Type", "application/json");
    // this.httpHeaders.set("enctype", "multipart/form-data");
    // this.httpHeaders.set("Authorization", `Basic ${btoa('sonauser@blpadmin.co.in:VbPs@53eFsJx!')}`)
    this.httpHeaders = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        enctype: "multipart/form-data",
        Authorization: "Basic " + btoa("sonauser@blpadmin.co.in:VbPs@53eFsJx!"),
      }),
    };
    // this.httpHeaders = {
    //   headers: new HttpHeaders({
    //   "Content-Type": "multipart/form-data; boundary=XXX",
    //     // "enctype":"multipart/form-data",
    //    "Authorization": "Basic " + btoa('sonauser@blpadmin.co.in:VbPs@53eFsJx!'),
    //   }),
    //   };
  }
  getInsights(data) {
    this.db
      .collection("/Asset_OEE/SONA/analytics", (ref) =>
        ref
          .where("component", "==", data.componet)
          .where("asset", "==", data.asset)
          .where("asset code", "==", data.assetcode)
      )
      .get();
  }
  authExplore() {
    // console.log(this.auth.currentUser)
    this.db.persistenceEnabled$.subscribe((f) => {
      // console.log(f)
    });
  }
  loggedin() {
    if (localStorage.getItem("user")) {
      return true;
    } else {
      return false;
    }
  }
  updatesite(json) {}
  getdetailsbyuser(params) {
    return this.db
      .collection("/Asset_OEE/SONA/User", (ref) =>
        ref.where("userid", "==", params.email)
      )
      .snapshotChanges();
  }

  getCustomer(params) {
    return this.db.collection(`/Asset_OEE/${params.customerId}/LiveData`).get();
  }
  getsitelist(params) {
    return this.db
      .collection("/Asset_OEE/SONA/User", (ref) =>
        ref.where("role", "==", params.role)
      )
      .get();
  }

  getconduct_vib_temp(json?) {
    if (json.collection != "HistoryData") {
      return this.db
        .collection(
          `/Asset_OEE/SONA/${json.collection}/${json.asset}/${json.assetcode}`,
          (ref) =>
            ref
              .where("timestamp", ">=", moment(json.startDate).toDate())
              .where("timestamp", "<=", moment(json.endDate).toDate())
              .orderBy("timestamp", "desc")
        )
        .get();
    } else {
      let data = {
        customerId: "SON",
        assetcode: json.assetcode,
        startTime: moment(json.startDate).unix(),
        endTime: moment(json.endDate).unix(),
      };
      if (json.tags && json.tags.length) {
        data["tags"] = json.tags;
      }
      return this.http.post(this.domain + "api/bigtable/select", data, {
        headers: this.httpHeaders,
      });
    }
  }
  gethistorydata(json) {
    return this.db.collection(
      "/Asset_OEE/SONA/Aggregate10MinuiteData/Conveyer/SON01FS02H01",
      (ref) =>
        ref
          .where("timestamp", ">=", json.startDate)
          .where("timestamp", "<=", json.endDate)
    );
  }
  signup(json) {
    return this.db.collection("/Asset_OEE/SONA/User").add(json);
  }
  livedata(assetcode) {
    return this.db
      .collection("/Asset_OEE/SONA/LiveData", (ref) =>
        ref.where("assetcode", "==", assetcode)
      )
      .valueChanges();
  }
  staticlivedata(assetcode?) {
    if (assetcode != undefined) {
      return this.db
        .collection("/Asset_OEE/SONA/LiveData", (ref) =>
          ref.where("assetcode", "==", assetcode)
        )
        .get();
    } else {
      return this.db.collection("/Asset_OEE/SONA/LiveData").get();
    }
  }
  getthreshold(tag?, assetcode?) {
    if (tag != undefined) {
      return this.db
        .collection("/Asset_OEE/SONA/Tags", (ref) =>
          ref.where("tag", "==", tag)
        )
        .get();
    } else if (assetcode != undefined) {
      return this.db
        .collection("/Asset_OEE/SONA/Tags", (ref) =>
          ref.where("assetcode", "==", assetcode)
        )
        .get();
    } else {
      return this.db.collection("/Asset_OEE/SONA/Tags").get();
    }
  }

  getindicatordata(json) {
    return this.db
      .collection("/Asset_OEE/SONA/HealthData", (ref) =>
        ref.where("assetcode", "==", json.assetcode)
      )
      .get();
  }
  getWanrningByLevel(json) {
    let level = json.level;
    let id = json.id;
    let customerId = json.customerId;
    switch (level) {
      case "site":
        return this.db
          .collection("/Asset_OEE/SONA/HealthData", (ref) =>
            ref.where("siteId", "==", id).where("customerId", "==", customerId)
          )
          .get();
        break;
      case "department":
        return this.db
          .collection("/Asset_OEE/SONA/HealthData", (ref) =>
            ref
              .where("departmentId", "==", id)
              .where("customerId", "==", customerId)
          )
          .get();
        break;
      case "line":
        return this.db
          .collection("/Asset_OEE/SONA/HealthData", (ref) =>
            ref.where("lineId", "==", id).where("customerId", "==", customerId)
          )
          .get();
        break;
      default:
        return this.db
          .collection("/Asset_OEE/SONA/HealthData", (ref) =>
            ref.where("siteId", "==", id).where("customerId", "==", customerId)
          )
          .get();
        break;
    }
  }
  getalerts(json) {
    if (json.download) {
      for (let key in json.id) {
        return this.db
          .collection("/Asset_OEE/SONA/WarnData", (ref) =>
            ref
              //  .where(key, "==", json.id[key])
              .where("timestamp", ">=", json.startDate)
              .where("timestamp", "<=", json.endDate)
          )
          .get();
      }
    }
    if (
      json.startDate != undefined &&
      json.endDate != undefined &&
      !json.download
    ) {
      for (let key in json.id) {
        return this.db
          .collection("/Asset_OEE/SONA/WarnData", (ref) =>
            ref
              .where(key, "==", json.id[key])
              .orderBy("timestamp", "desc")
              .where("timestamp", ">=", json.startDate)
              .where("timestamp", "<", json.endDate)
          )
          .snapshotChanges();
      }
    } else if (json.startDate != undefined) {
      for (let key in json.id) {
        return this.db
          .collection("/Asset_OEE/SONA/WarnData", (ref) =>
            ref
              .where(key, "==", json.id[key])
              .orderBy("timestamp", "desc")
              .where("timestamp", ">=", json.startDate)
          )
          .snapshotChanges();
      }
    }
  }
  getAlertsData(params) {
    return this.db.collection("/Asset_OEE/SONA/WarnData").get();
  }
  // getAlertsData(params){
  //   console.log(params)
  //   this.db.collection('/Asset_OEE/SONA/WarnData').snapshotChanges().subscribe(ref =>{
  //     console.log(ref)
  //     let count = ref.length
  //     console.log(count)
  //   });
  // }
  updatealert(json) {
    let id = json._id;
    delete json._id;
    return this.db.collection("/Asset_OEE/SONA/WarnData").doc(id).update(json);
  }
  updatehealth(warningData) {
    return this.http.post(
      "https://us-central1-sona-blw.cloudfunctions.net/AssetHealth/health",
      { warningData: warningData },
      this.httpHeaders
    );
  }
  sendreport(data, fileName) {
    let user = localStorage.getItem("user");
    let params = {
      fields: {
        to: user,
        subject: "Report",
        text: "Hi\n\nPFA the report.\n\nThanks & Regards\n____________________\nBLP Industry.AI\nA Bharat Light & Power Group Co.\nWebsite: www.blp.co.in",
      },
      dataString: data,
      fileName: fileName,
    };
    return this.http.post(
      "https://us-central1-sona-blw.cloudfunctions.net/AssetHealth/mail",
      params,
      this.httpHeaders
    );
  }
  uploadFile(data: any) {
    return this.http.post(
      "https://us-central1-sona-blw.cloudfunctions.net/AssetHealth/uploadReport",
      data,
      {
        headers: new HttpHeaders({
          enctype: "multipart/form-data",
          Authorization:
            "Basic " + btoa("sonauser@blpadmin.co.in:VbPs@53eFsJx!"),
        }),
      }
    );
  }

  getHealthHistory(date) {
    return this.db
      .collection("/Asset_OEE/SONA/HealthDataHistory", (ref) =>
        ref.orderBy("timestamp", "asc").where("timestamp", ">=", date)
      )
      .get();
  }
  getFileList() {
    return this.db
      .collection("/Asset_OEE/SONA/Reports", (ref) =>
        ref.orderBy("timestamp", "desc")
      )
      .get();
  }
  getFurnaceData(params) {
    return this.db
      .collection("/Asset_OEE/SONA/LiveData")
      .doc(params.docId)
      .valueChanges();
  }
  getFurnaceHistory(params) {
    return this.db
      .collection(
        "/Asset_OEE/SONA/HistoryData/Furnace_CBM/" + params.assetcode,
        (ref) =>
          ref
            .where("timestamp", ">=", params.startDate)
            .where("timestamp", "<=", params.endDate)
            .orderBy("timestamp", "desc")
      )
      .get();
  }
  getLastUpdatedSEC(params) {
    return this.db
      .collection("/Asset_OEE/SONA/SEC/", (ref) =>
        ref.orderBy("date", "desc").limit(2)
      )
      .get();
  }
  getHistorySec(params) {
    return this.db
      .collection("/Asset_OEE/SONA/SEC/", (ref) =>
        ref
          .orderBy("date", "desc")
          .where("date", ">=", params.startDate)
          .where("date", "<=", params.endDate)
      )
      .get();
  }
  getHistoryShopFloor(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Shopfloor/", (ref) =>
        ref
          .orderBy("Date", "desc")
          .where("Date", ">=", params.startDate)
          .where("Date", "<=", params.endDate)
      )
      .get();
  }
  getHistoryDaySec(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Day_SEC/", (ref) =>
        ref
          .orderBy("Date", "desc")
          .where("Date", ">=", params.startDate)
          .where("Date", "<=", params.endDate)
      )
      .get();
  }
  getLineListdata() {
    return this.db.collection("/Asset_OEE/SONA/Day_SEC/").get();
  }
  getDaywiseData(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Day_SEC/", (ref) =>
        ref
          .where("Date", ">=", params.startDate)
          .where("Date", "<=", params.endDate)
      )
      .get();
  }
  getHourlyData(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Hourly/", (ref) =>
        ref
          .where("Timestamp", ">=", params.startDate)
          .where("Timestamp", "<=", params.endDate)
          .where("Line_id", "==", params.lineId)
      )
      .get();
  }
  getLinewiseData(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Day_SEC/", (ref) =>
        ref
          .where("Date", ">=", params.startDate)
          .where("Date", "<=", params.endDate)
          .where("Line_ID", "==", params.lineId)
      )
      .get();
  }
  getLines() {
    return this.db.collection("/Asset_OEE/SONA/lineId/").get();
  }
  getCorrelationData() {
    return this.db.collection("/Asset_OEE/SONA/Day_SEC/").get();
  }
  getBatchWiseSEC(params) {
    return this.db
      .collection("/Asset_OEE/SONA/Hourly/", (ref) =>
        ref
          .where("batch_start", ">=", params.startDate)
          .where("batch_start", "<=", params.endDate)
          .where("Line_id", "==", params.lineId)
      )
      .get();
  }
  reportGeneration(body: any, apiUrl: any) {
    return this.http.post(apiUrl, body, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("sonauser@blpadmin.co.in:VbPs@53eFsJx!"),
      }),
    });
  }
  getAggregateData(params?: any) {
    return new Promise((resolve, reject) => {
      params["fromDate"] = moment(params["fromDate"]).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let aggregationHeader: any, refreshTokenParan: any;
      let tokens: any = localStorage.getItem("SONA_tokens") || "";
      tokens = JSON.parse(tokens);
      if (tokens && tokens != "") {
        refreshTokenParan = { refresh: tokens["refresh"] };
        aggregationHeader = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens["access"]}`,
          }),
        };
      } else {
        aggregationHeader = {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
          }),
        };
      }
      params["toDate"] = moment(params["toDate"]).format("YYYY-MM-DD HH:mm:ss");
      if (params.interval != "Live") {
        return this.http
          .post(`${this.domainUrl}/aggregatedData/`, params, aggregationHeader)
          .subscribe(
            (response: any) => {
              if (response["status"] == "success") {
                resolve(response);
              } else {
                resolve(response);
              }
            },
            (err: any) => {
              this.getRefreshToken(refreshTokenParan)
                .then((rsult: any) => {
                  this.getAggregateData(params);
                })
                .catch((err) => {
                  this.util.setLoaderValue(false);
                });
            }
          );
      } else {
        return this.http
          .post(`${this.domainUrl}/liveData/`, params, aggregationHeader)
          .subscribe(
            (response: any) => {
              if (response["status"] == "success") {
                resolve(response);
              } else {
                resolve(response);
              }
            },
            (err: any) => {
              this.getRefreshToken(refreshTokenParan)
                .then((rsult: any) => {
                  this.getAggregateData(params);
                })
                .catch((err) => {
                  this.util.setLoaderValue(false);
                });
            }
          );
      }
    });
  }
  getRefreshToken(param: any) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(
          "https://sona-dserver-v1-xm3dgannva-uc.a.run.app/token/refresh/",
          param,
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
            }),
          }
        )
        .subscribe(
          (result: any) => {
            localStorage.setItem("SONA_tokens", JSON.stringify(result));
            this.util.setToken(result);
            resolve(true);
          },
          (err) => {
            localStorage.removeItem("SONA_tokens");
            this.router.navigate(["login"]);
          }
        );
    });
  }
  uploadFileintoFirebaseStorage(filepath: any, file: any) {
    return new Promise((resolve, reject) => {
      const fileRef = this.firebaseStorage.ref(filepath);
      const task = this.firebaseStorage.upload(filepath, file);
      task.snapshotChanges().subscribe((response: any) => {
        resolve({ result: response, status: "success" });
      }),
        (err: any) => {
          reject({ result: err, status: "failure" });
        };
    });
  }
  deleteFileinFirebaseStorage(filepath: any) {
    return new Promise((resolve, reject) => {
      const storageRef = this.firebaseStorage.ref(filepath);
      storageRef.delete().subscribe(
        () => {
          resolve({ message: "File deleted successfully" });
        },
        (error) => {
          reject({ message: `Error deleting file ${error}` });
          console.error();
        }
      );
    });
  }
  getFileUrlfromFirestorage(fileData: any, length?: any) {
    return new Promise(async (resolve, reject) => {
      let file = fileData.split("/");
      if (length) {
        file = file.slice(file.length - length).join("/");
      } else {
        file = file.slice(file.length - 2).join("/");
      }
      const filePath = this.firebaseStorage.ref(file);
      await filePath.getDownloadURL().subscribe((url: any) => {
        resolve({ url: url });
      }),
        (err: any) => {
          reject({ message: err });
        };
    });
  }
  uploadReportFile(data: any) {
    return this.db.collection("/Asset_OEE/SONA/Reports").add(data);
  }
  getRulReportData() {
    return this.db.collection("/Asset_OEE/SONA/analytics").valueChanges();
  }

  //Downtime Analytics
  getEquipmentDowntime(params: any) {
    return this.db
      .collection("/Asset_OEE/SONA/Manual_Breakdown_Raw_Data", (ref) =>
        ref
          .where("timestamp", ">=", params.startDate)
          .where("timestamp", "<=", params.endDate)
      )
      .valueChanges();
  }
  getEquipmentDowntimeData(params: any) {
    return this.db.collection("/Asset_OEE/SONA/Breakdown_Data").get();
  }
  addEquipmentBreakdown(params: any) {
    return this.db.collection("/Asset_OEE/SONA/Breakdown_Data").add(params);
  }
  getEquipmentDetails(params: any) {
    return this.db.collection("/Asset_OEE/SONA/Equipments").get();
  }
  addEquipmentDetails(params: any) {
    return this.db.collection("/Asset_OEE/SONA/Equipments").add(params);
  }

  //Quality Check
  // getBilletTempQuality(params: any){
  //   return this.db.collection("/Asset_OEE/SONA/Billet_Tem_Quality", (ref)=>
  //   ref.where('batch_start','>=',params.startDate).where('batch_start','<=',params.endDate)).get();
  // }
  getBilletTempQuality(params: any) {
    console.log(params);
    return this.db
      .collection("/Asset_OEE/SONA/Billet_Live_Inspection", (ref) =>
        ref
          .where("timestamp", ">=", params.startDate)
          .where("timestamp", "<=", params.endDate)
      )
      .valueChanges();
  }
  getQuestions() {
    return this.db.collection("/Asset_OEE/SONA/conversationAI").valueChanges();
  }
  // Tbm Dashboard
  updateDocToFirestore(collection: string, data: any, id: string) {
    return this.db
      .collection(`/Asset_OEE/SONA/${collection}`)
      .doc(id)
      .set(data, { merge: true });
  }
  getFireBaseData(params?: any) {
    return new Promise((resolve, reject) => {
      let collectionName = params.collection?`/Asset_OEE/SONA/${params.collection}`:params.address;
      let collectionPath = params.collectionPath;
      let conditions = params.conditions;
      if (params.conditions && typeof params.conditions == "string") {
        conditions = JSON.parse(params.conditions);
      }
      let method = "get";
      if (params.method) {
        method = params.method;
      }
      let limit: any;
      let orderByKey: any;
      let order: any;
      if (params.orderby) {
        orderByKey = params.orderby;
        order = params.order;
      }
      if (params.limit) {
        limit = parseInt(params.limit);
      }
      if (collectionName) {
        let docRef: any;
        if (collectionName && params.collectionPath == undefined) {
          docRef = this.db.collection(`${collectionName}`).ref;
        } else if (params.collection && params.collectionPath) {
          docRef = this.db.collection(
            `${collectionName}/${collectionPath}`
          ).ref;
        }
        if (conditions && conditions.length > 0) {
          conditions.forEach((element: any) => {
            docRef = docRef.where(element.key, element.symbol, element.value);
          });
        }
        if (orderByKey) {
          docRef = docRef.orderBy(orderByKey, order);
        }
        if (limit) {
          docRef = docRef.limit(limit);
        }
        docRef.get().then((element: any) => {
          if (element.size) {
            let promises: any = [];
            let resultData = [];
            element.forEach((doc: any) => {
              resultData = { ...doc.data(), ...{ doc_id: doc.id } };
              promises.push(resultData);
              resolve({ data: promises, status: "success" });
            });
          } else {
            resolve({ message: "No Document Found", status: "failure" ,data:[]});
          }
        });
      } else {
        reject({ message: "Missing collectionName", status: "failure" });
      }
    });
  }
  updateFirebaseData(params?: any) {
    return new Promise((resolve, reject) => {
      {
        let collection = `/Asset_OEE/SONA/${params.collection}`;
        let documentId = params.id;
        let method = params.method;
        let dataToUpdate = params.dataToUpdate;
        let docRef = undefined;
        if (collection) {
          docRef = this.db.collection(collection);
          if (documentId) {
            docRef = docRef.doc(documentId);
            if (method == "set") {
              docRef = docRef
                .set(dataToUpdate, { merge: true })
                .then((res) => {
                  resolve({
                    status: "success",
                    message: "Data inserted Successfully",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  reject({ status: "failure", message: err });
                });
            } else if (method == "update") {
              docRef = docRef
                .update(dataToUpdate)
                .then((res) => {
                  resolve({
                    status: "success",
                    message: "Data updated Successfully",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  reject({ status: "failure", message: err });
                });
            } else if (method == "delete") {
              docRef = docRef
                .delete()
                .then((res) => {
                  resolve({
                    status: "success",
                    message: "Data Deleted Successfully",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  reject({ status: "failure", message: err });
                });
            } else {
              reject({ status: "failure", message: "Missing method" });
            }
          } else if (documentId == "" && method == "set") {
            docRef = docRef
              .add(dataToUpdate)
              .then((res) => {
                resolve({
                  status: "success",
                  message: "Data inserted Successfully",
                });
              })
              .catch((err) => {
                console.log(err);
                reject({ status: "failure", message: err });
              });
          } else {
            reject({ message: "Document ID is Missing", status: "failure" });
          }
        } else {
          reject({ message: "Collection Name is Missing", status: "failure" });
        }
      }
    });
  }
}

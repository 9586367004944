import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutContainerComponent } from "./layout-container/layout-container.component";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { HighchartsChartModule } from "highcharts-angular";
import { MatTreeModule } from "@angular/material/tree";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { LoginComponent } from "./login/login.component";
// import { HttpcallsService } from "./httpcalls.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { HttpClientModule } from "@angular/common/http";
import { HttpcallsService } from "./services/httpcalls.service";
import { HistorySocketService } from "./services/historysocket.service";
import { FilterPipeModule } from 'ngx-filter-pipe';
import { ForgotComponent } from './forgot/forgot.component';
import { SubNavbarComponent } from './sub-navbar/sub-navbar.component';
import { ThemeDirective } from './directives/theme.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginatePipe } from './services/paginate.pipe';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import more from 'highcharts/highcharts-more.src';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonModule } from '@angular/material/button';
import{CUSTOM_ELEMENTS_SCHEMA} from '@angular/core'
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackbarSeverity } from 'mat-snackbar-severity';

import { AngularFireStorageModule,AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ConversationAiComponent } from './conversation-ai/conversation-ai.component';
import {NgxBlpConversationAIModule} from 'ngx-blp-conversation-ai';
import { AdvancedAnalyticsComponent } from './advanced-analytics/advanced-analytics.component'
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ComponentsModule } from "./components/components.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { RouterModule } from "@angular/router";;
export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}
@NgModule({
  declarations: [
    AppComponent,
    LayoutContainerComponent,
    LoginComponent,
    ForgotComponent,
    SubNavbarComponent,
    ThemeDirective,
    PaginatePipe,
    UploadFilesComponent,
    ConversationAiComponent,
    AdvancedAnalyticsComponent,
  ],
  imports: [
    BrowserModule,
    AngularFontAwesomeModule,
    FilterPipeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatTreeModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    HttpClientModule,
    NgxPaginationModule,
    MatSnackBarModule,
    MatButtonModule,
    NgbModalModule,
    AngularFireStorageModule,
    NgxBlpConversationAIModule,
    Ng2SearchPipeModule,
    ComponentsModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule,
  ],
  providers: [
    CookieService,
    Title,
    HttpcallsService,
    HistorySocketService,
    NgbModal,
    { provide: HIGHCHARTS_MODULES, useFactory: highchartsModules },
    NgxPaginationModule,
    MatSnackbarSeverity,
    NgbModalModule,
    NgbModule,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutContainerComponent } from "./layout-container/layout-container.component";
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from "./login/login.component";
import { AuthGuardGuard } from "./services/auth-guard.guard";
import { UploadFilesComponent } from "./upload-files/upload-files.component";
import { ConversationAiComponent } from "./conversation-ai/conversation-ai.component";
import { AdvancedAnalyticsComponent } from "./advanced-analytics/advanced-analytics.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },

  {
    path: "common",
    component: LayoutContainerComponent,
    canActivate: [AuthGuardGuard],
    children: [
      {
        path: "predictive-maintenance",
        loadChildren: () =>
          import("./predictive-maintenance/predictive-maintenance.module").then(
            (m) => m.PredictiveMaintenanceModule
          ),
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then(
            (m) => m.AdminModule
          ),
        data: {
          title: "Admin Page"
        }
      },
      {
        path: "monitor",
        loadChildren: () => import("./monitor/monitor.module").then((m) => m.MonitorModule),
        data: {
          title: "Monitor Page"
        }
      }, {
        path: "compare",
        loadChildren: () => import("./compare/compare.module").then((m) => m.CompareModule),
        data: {
          title: "Compare Page"
        }
      },
      {
        path: "analytics",
        loadChildren: () => import("./analytics/analytics.module").then((m) => m.AnalyticsModule),
        data: {
          title: "Analytics Page"
        }
      },
      {
        path: "downtime-analytics",
        loadChildren: () => import("./downtime-analytics/downtime-analytics.module").then((m) => m.DowntimeAnalyticsModule),
        data: {
          title: "Downtime Analytics Page"
        }
      },
      {
        path: "reports",
        component: UploadFilesComponent,
        data: {
          title: "Reports Page"
        }
      }, {
        path: "alerts",
        loadChildren: () => import("./alerts/alerts.module").then((m) => m.AlertsModule),
        data: {
          title: "Alrts Page"
        }
      },
      {
        path: "maintenance",
        loadChildren: () => import("./maintenance/maintenance.module").then((m) => m.MaintenanceModule),
        data: {
          title: "Maintenance Page"
        }
      },
      {
        path: "furnace-cbm",
        loadChildren: () => import("./furnace-cbm/furnace-cbm.module").then((m) => m.FurnaceCbmModule),
        data: {
          title: "Furnace CBM"
        }
      },

      {
        path: "secc",
        loadChildren: () => import("./secc/secc.module").then((m) => m.SeccModule),
        data: {
          title: "SECC"
        }
      },
      {
        path: "quality",
        loadChildren: () => import("./quality/quality.module").then((m) => m.QualityModule),
        data: {
          title: "Quality"
        }
      },
      {
        path: "conserve-ai/:id",
        loadChildren: () => import("../app/energy-meters/energy-meters.module").then((m) => m.EnergyMetersModule),
        data: {
          title: "Energy Meters"
        },
        canActivate: [AuthGuardGuard],
      },
      {
        path: "conversationai",
        component: ConversationAiComponent,
        data: {
          title: "Conversation AI",
        },
      },
      {
        path: "advanced_analytics",
        component: AdvancedAnalyticsComponent,
        data: {
          title: "Advanced Analytics",
        },
      },
    ],
  },


  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "forgot",
    component: ForgotComponent,
    data: {
      title: "Forgot Page",
    },
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit,OnChanges {
  data
  startDate = moment().subtract(15, "minutes").format("MM/DD/YYYY HH:mm:ss");
  endDate = moment().format("MM/DD/YYYY HH:mm:ss");
  @Input() isSingle  =false
  @Output() selectedDates = new EventEmitter()
  @Input('data')
  set in(val:any){
    this.data = val
    if( this.data && this.data.length){
      this.startDate = this.data[0].dates?this.data[0].dates.startDate:this.data[0].startDate
      this.endDate = this.data[0].dates?this.data[0].dates.endDate:this.data[0].endDate;
      if(this.isSingle){
        this.dataplaceholder = `${moment(new Date(this.startDate)).format("DD-MM-YYYY")}`
      }else{
        this.dataplaceholder = `${moment(new Date(this.startDate)).format("DD-MM-YYYY HH:mm")} - ${moment(new Date(this.endDate)).format("DD-MM-YYYY HH:mm")}`
      }
      // this.selectedDate['startDate'] = moment(this.startDate).format("MMM, DD hh:mm a"),
      // this.selectedDate['endDate'] =moment(this.endDate).format("MMM, DD hh:mm a")
      this.selectedCollection = this.data[0].collection
    }
     else{
       this.startDate = this.data.startDate
      this.endDate =this.data.endDate;
      if(this.isSingle){
        this.dataplaceholder = `${moment().format("DD-MM-YYYY")}`
      }else{
        this.dataplaceholder = `${moment(new Date(this.startDate)).format("DD-MM-YYYY HH:mm")} - ${moment(new Date(this.endDate)).format("DD-MM-YYYY HH:mm")}`
      }
     }
  }
  selecteddate;
  timePicker= true;
  selected: any = { startDate: null, endDate: null }
  selectedDate : {
    startDate;endDate;
  }
  format1= "DD-MM-YY,HH:mm"
  format2= "DD-MM-YYYY"
  maxDate: moment.Moment = moment().endOf("minute");
  // maxDate: moment.Moment = moment(this.endDate).endOf('week');
  localdata = {
    displayFormat: this.isSingle? this.format2:this.format1,
  };
  rangesnew = {
    Today: [moment, moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  options: any = {
    alwaysShowCalendars: true,
  };
  dataplaceholder = "Choose Date";
  selectedCollection: any;
  // date picker end
  constructor() {
  }
  ngOnChanges(changes: SimpleChanges): void {
        this.selected["startDate"] = moment(this.startDate).toDate();
     this.selected["endDate"] = moment(this.endDate).toDate();

  }
  ngAfterViewInit(): void {
  }
  ngOnInit() {
     this.selected["startDate"] = moment(this.startDate).toDate();
     this.selected["endDate"] = moment(this.endDate).toDate();
  }
  onDatesChange(e:any){
    this.selectedDates.emit(e)
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivate,
  Router,
} from "@angular/router";
import { environment } from 'src/environments/environment';
import { FirestoreService } from './firestore.service';
import { HttpcallsService } from "./httpcalls.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardGuard implements CanActivate {
  constructor(private router: Router, private service: HttpcallsService, private fs: FirestoreService) {}
  canActivate(): boolean {
    if(environment.SERVICE_CALL == "http"){
      if (this.service.loggedin()) {
        return true;
      } else {
        this.router.navigate(["/login"]);
        return false;
      }
    }else{
      if (this.fs.loggedin()) {
        return true;
      } else {
        this.router.navigate(["/login"]);
        return false;
      }
    }
   
  }
}

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import jwt_decode from "jwt-decode";
import { env } from 'process';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class HttpcallsService {
  // jwt_decode = ;
  httpHeaders;
  token;
  authHeaders: any;
  // baseUrl = "http://localhost:9330/";
  baseUrl = environment.API_ENDPOINT;
  accessToken = "";
  constructor(private http: HttpClient) {
    let decrypt = (this.httpHeaders = new HttpHeaders());
    this.authHeaders = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("admin@blpadmin.co.in:Sjq73cdd5SAhs"),
      }),
    };
    this.httpHeaders.set("Content-Type", "application/json");
  }
  loggedin() {
    this.token = localStorage.getItem("token");
    let decode = jwt_decode(this.token);
    //console.log(decode);
    if (decode.exp != undefined) {
      const date = new Date(0);
      const expDate = date.setUTCSeconds(decode.exp);
      //console.log('exp:' + expDate.valueOf());
      //console.log('curent:' + new Date().valueOf());
      //console.log(expDate.valueOf() - new Date().valueOf())
      if (expDate.valueOf() > new Date().valueOf()) {
        this.httpHeaders.set("Authorization", `Bearer${this.token}`);
        return true;
      } else {
        return false;
      }
    } else {
      //console.log("not")
      return false;
    }
  }
  updatesite(json) {
    return this.http.post(
      `${this.baseUrl}api/user/updateUserDetails`,
      json,
      this.httpHeaders
    );
  }
  getdetailsbyuser(params) {
    return this.http.get(`${this.baseUrl}api/user/getDetailsByUser`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  deleteusser(params) {
    return this.http.get(`${this.baseUrl}api/user/delete`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  getCustomer(params) {
    return this.http.get(`${this.baseUrl}api/live/getSiteDetails`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  getsitelist(params) {
    return this.http.get(`${this.baseUrl}api/user/getDetailsByRole`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  staticlivedata(params) {
    return this.http.get(`${this.baseUrl}api/live/get`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  getOeeOverView(params?) {
    return this.http.post(`${this.baseUrl}api/report/getDashboardReport`, {
      headers: this.httpHeaders,
      params: params,
    });
  }
  getMttrMtbfOeeDashboard() {
    return this.http.post(
      `${this.baseUrl}api/report/mttrMtbfReport`,
      this.httpHeaders
    );
  }
  getProductionAggregate() {
    return this.http.post(
      `${this.baseUrl}api/report/productionAggregateReport`,
      this.httpHeaders
    );
  }
  getmapdata() {
    return this.http.post(
      `${this.baseUrl}api/report/getGlobalReport`,
      this.httpHeaders
    );
  }

  getdowntimereport(json) {
    return this.http.post(
      `${this.baseUrl}api/report/downtimeReport`,
      json,
      this.httpHeaders
    );
  }
  getlosses(json?) {
    return this.http.post(`${this.baseUrl}api/loss/get`, this.httpHeaders);
  }
  getAssetCount(json?) {
    return this.http.post(
      `${this.baseUrl}api/report/assetCount`,
      this.httpHeaders
    );
  }
  getplantproduction(json?) {
    return this.http.post(
      `${this.baseUrl}api/report/productionReport`,
      json,
      this.httpHeaders
    );
  }
  getlossByMachines(json?) {
    return this.http.post(
      `${this.baseUrl}api/report/lossByMachines`,
      this.httpHeaders
    );
  }

  getmachineRunState(json?) {
    return this.http.post(
      `${this.baseUrl}api/report/machineRunState`,
      this.httpHeaders
    );
  }
  getlossTypeReport(json?) {
    return this.http.post(
      `${this.baseUrl}api/loss/lossTypeReport`,
      this.httpHeaders
    );
  }

  getquality() {
    return this.http.post(
      `${this.baseUrl}api/report/quality`,
      this.httpHeaders
    );
  }
  getavailability() {
    return this.http.post(
      `${this.baseUrl}api/report/availability`,
      this.httpHeaders
    );
  }
  getperformance() {
    return this.http.post(
      `${this.baseUrl}api/report/performance`,
      this.httpHeaders
    );
  }
  getconduct_vib_temp(json?) {
    return this.http.post(
      `${this.baseUrl}api/history/get`,
      json,
      this.httpHeaders
    );
  }
  signup(json) {
    return this.http.post(
      `${this.baseUrl}api/user/signup`,
      json,
      this.httpHeaders
    );
  }
  login(json) {
    return this.http.post(
      `${this.baseUrl}api/user/login`,
      json,
      this.httpHeaders
    );
  }
  getindicatordata(json) {
    return this.http.post(
      `${this.baseUrl}api/warn/get`,
      json,
      this.httpHeaders
    );
  }
  getWanrningByLevel(json) {
    return this.http.post(
      `${this.baseUrl}api/warn/getByLevel`,
      json,
      this.httpHeaders
    );
  }
  getsitelevelhealth(json) {
    return this.http.post(
      `${this.baseUrl}api/warn/getCompanyAssetState
      `,
      json,
      this.httpHeaders
    );
  }
  getalerts(json) {
    return this.http.post(
      `${this.baseUrl}api/warn/getAlertsByLevel`,
      json,
      this.httpHeaders
    );
  }

  updatealert(json) {
    return this.http.post(
      `${this.baseUrl}api/warn/updateAlert`,
      json,
      this.httpHeaders
    );
  }
  blpUsersAuthentication(params?: any, endPoint?: any) {
    return this.http.post(endPoint, params, this.authHeaders);
  }

  getToken(json?) {
    let header = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa("sonauser@blpadmin.co.in:VbPs@53eFsJx!"),
        Origin: "http://blp.com",
      }),
    };
    return this.http.post(
      "https://asia-south1-sona-blw.cloudfunctions.net/Custom-Token-Authentication/generateCustomToken",
      json,
      header
    );
  }
  getAccessToke(param: any) {
    return this.http.post(
      "https://sona-dserver-v1-xm3dgannva-uc.a.run.app/token/",
      param,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      }
    );
  }
  generateAnalyticsReport(params: any) {
    return this.http.post(
      "https://asia-south1-sona-blw.cloudfunctions.net/AdvancedAnalytics",
      params,
      {
        headers: this.authHeaders,
      }
    );
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() values: any[] = [];
  @Input() controlName: string = ""
  @Input() selected: any;
  @Input() selectedArr: any[] = [];
  @Input() disabled: boolean = false;
  @Input() type: string = "string";
  @Input() displayMember: string = "name";
  @Input() isLabel: boolean = false;
  @Input() isMultiple: boolean = false;

  @Output() newSelectionEvent = new EventEmitter<string>();
  dropdownData: any[] = [];

  constructor() { }

  ngOnInit() {
    if (this.type == "multiplekey") {
      let displayMemberArr = this.displayMember.split(",");
      this.values.forEach((data: any) => {
        data["customDisplay"] = " ";
        for (let d = 0; d < displayMemberArr.length; d++) {
          if (d == displayMemberArr.length - 1) {
            data["customDisplay"] += data[displayMemberArr[d]];
          } else {
            data["customDisplay"] += data[displayMemberArr[d]] + " - ";
          }
        }
      });

    }
    this.dropdownData = this.values
  }
  ngOnChanges(change: SimpleChanges) {
    if (change.displayMember) {
      this.displayMember = change.displayMember.currentValue;
    }
    if (change.selected) {
      this.selected = change.selected.currentValue;
    }
    if (change.type) {
      this.type = change.type.currentValue;
    }
    if (change.values) {
      this.values = change.values.currentValue;
      this.dropdownData = this.values;
    }
    if (this.type == "object") {
      // if(this.selected && this.selected["equipmentId"] && this.selected["equipmentName"]) {
      //   this._displayMember = "equipmentName";
      // }
      if (this.selected && this.selected[this.displayMember]) {
        let selectedData = this.dropdownData.filter((filterData) => {
          return filterData[this.displayMember] == this.selected[this.displayMember];
        })
        if (selectedData.length > 0) {
          this.selected = selectedData[0];

        }
      }
    }
  }

  selectionChanged(event: any) {
    this.newSelectionEvent.emit(event.value);
  }



}

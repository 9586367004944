import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {filter} from 'rxjs/operators'
import { environment } from 'src/environments/environment';
declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OEE';
  constructor(private router:Router,private activatedRoute: ActivatedRoute,private titleService: Title ){
    const navEndEvents =router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event : NavigationEnd) =>{
      const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          gtag("config", environment.prod_tracking, {
            'user_id': localStorage.getItem("user"),
            'page_path': event.urlAfterRedirects,
            'page_title': data.title,
          });
        });
    })
  }
  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  } 
}

import { Component, Input, OnInit } from "@angular/core";
import { StockChart } from "angular-highcharts";

@Component({
  selector: "app-stockchart",
  templateUrl: "./stockchart.component.html",
  styleUrls: ["./stockchart.component.scss"],
})
export class StockchartComponent implements OnInit {
  @Input() data: any;
  stock: StockChart;
  yAxis: {
    lineColor: "var(--graph-text-color)";
    gridLineColor: "var(--gridline-color)";
    lineWidth: 0;
    endOnTick: false;
    labels: {
      style: {
        color: "var(--graph-text-color)";
        fontSize: "12";
      };
    };
    title: {
      style: {
        color: "var(--graph-text-color)";
        fontSize: "14";
      };
    };
  };
  constructor() {}

  ngOnInit() {
    this.plotGraph(this.data);
  }
  plotGraph(data) {
    this.stock = new StockChart({
      chart: {
        reflow: true,
        type: "line",
        style: {
          fontFamily: "SF Pro Text",
          color: "var(--primary-text-color)",
        },
        backgroundColor: "transparent",
      },
      time: {
        useUTC: false,
      },
      credits: {
        enabled: false,
        style: {
          color: "#999999",
          cursor: "pointer",
          fontSize: "9px",
        },
        text: "Highcharts.com",
      },
      rangeSelector: {
        selected: 5,
        enabled: false,
      },
      navigator: {
        enabled: true,
      },
      title: {
        text: "",
      },
      xAxis: {
        lineColor: "#cccccc",
        lineWidth: 1,
        type: "datetime",
        labels: {
          style: {
            color: "#427bff",
            fontSize: "12px",
          },
        },
      },

      yAxis: data[0].yaxis ? data[0].yaxis : this.yAxis,
      //
      legend: {
        align: "center",
        enabled: true,
        verticalAlign: "bottom",
        layout: "horizontal",
        squareSymbol: true,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: "var(--graph-text-color)",
          fontWeight: "ligther",
          fontSize: "13",
        },
        y: 10,
      },
      plotOptions: {
        series: {
          turboThreshold: 50000,
          dataGrouping: {
            enabled: false,
          },
        },
      },
      series: data[0].series,
    });
  }
}

import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { resolve } from "url";
import { reject } from "lodash";
@Injectable({
  providedIn: "root",
})
export class DataService {
  node = {};
  updated_temp_data = new EventEmitter();
  activatedmodule = new EventEmitter();
  activatedsubmodule = new EventEmitter();
  selectednode = new EventEmitter();
  selectednode2 = new BehaviorSubject(this.node);
  updatedgraphdata = new EventEmitter();
  activated_indicators = new EventEmitter();
  getheiratchydata = new EventEmitter();
  play_actions = new EventEmitter();
  getLiveData = new EventEmitter();
  selectedreport = new BehaviorSubject('live');
  historydata = new EventEmitter();
  getComparedata = new EventEmitter();
  reportSatus = new BehaviorSubject("");
  loader = new BehaviorSubject('');
  selectetabindex = new EventEmitter();
  show_navbars = new EventEmitter();
  updatePlotBands = new EventEmitter();
  indicatorsDropdownData = new BehaviorSubject([]);
  selectedAssset = new BehaviorSubject([]);
  selectedTabData = new BehaviorSubject('');
  private activeThem = new BehaviorSubject('white');
  public adminTabsData = new BehaviorSubject('');
  constructor(private firebaseStorage :AngularFireStorage) {
  }
  sendIndicatorsSelectedTabData(data) {
    this.indicatorsDropdownData.next(data);
  }
  getFileUrlfromFirestorage(fileData:any,length?:any){
  return new Promise(async(resolve,reject)=>{
    let file=fileData.split('/')
    if(length){
      file=file.slice(((file.length)-length)).join('/')
    }
    else{
      file=file.slice(((file.length)-2)).join('/')
    }
  const filePath=  this.firebaseStorage.ref(file)
  await filePath.getDownloadURL().subscribe((url:any)=>{
  resolve ({url:url})
  }),(err:any)=>{
    reject({message:err})
  }
  })

  }

  sendSelectedAssetData(data:any){
this.selectedAssset.next(data)
  }
  sendSelectedTabData(data:any) {
    localStorage.setItem("lastUpdatedDataForAiInsights", JSON.stringify(data))
    this.selectedTabData.next(data)
  }
  sendDataToUpdatePlot(data) {
    this.updatePlotBands.emit(data);
  }
  activate_navbars(data) {
    this.show_navbars.emit(data);
  }
  sendUpdatedCopareData(data) {
    this.getComparedata.emit(data);
  }
  public getActiveTheme() {
    return this.activeThem.asObservable();
  }

  public setActiveThem(name) {
    this.activeThem.next(name);
  }
  getactivateloader():Observable<any>{
    return this.loader.asObservable();
  }
  activateloader(data) {
    this.loader.next(data);
  }
  sendreportStatus(data) {
    this.reportSatus.next(data);
  }
  sendhistorydata(data) {
    this.historydata.emit(data);
  }
  sendselectedreport(data) {
    this.selectedreport.next(data);
  }
  sendivedata(data) {
    this.getLiveData.emit(data)
  }
  send_h_data(data) {
    this.getheiratchydata.emit(data);
  }
  sendupdatedtempdata(data) {
    this.updated_temp_data.emit(data)
  }
  sendplayactions(data) {
    this.play_actions.emit(data)
  }
  sendSelectedModule(data) {
    this.activatedmodule.emit(data);
  }
  sendupdatedgraphdata(data) {
    this.updatedgraphdata.emit(data);
  }
  activeSubModule(data) {
    this.activatedsubmodule.emit(data);
  }
  sendselectednode(data) {
    this.selectednode.emit(data);
  }
  activate_indicators(data) {
    this.activated_indicators.emit(data);
  }
  sendselectednode2(data) {
    this.node = data;
    this.selectednode2.next(data);
  }
  sendselectedtabindex(data) {
    this.selectetabindex.emit(data);
  }
  admintabData(data){
    this.adminTabsData.next(data)
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { DataService } from '../services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpcallsService } from '../services/httpcalls.service';
import { FirestoreService } from '../services/firestore.service';
import _ from 'lodash';

@Component({
  selector: "app-advanced-analytics",
  templateUrl: "./advanced-analytics.component.html",
  styleUrls: ["./advanced-analytics.component.scss"],
})
export class AdvancedAnalyticsComponent implements OnInit {
  reportFilter: string;
  filtersList: any = [
    {
      type: "date",
      opens: "center",
      data: {
        startDate: moment().subtract(3, "days").startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
      },
      dropdowncategory: "date",
      labelName: "Date",
    },
    {
      type: "dropdown",
      data: [
        {
          analysis_name: "Univariate Analysis",
          analysis_id: "Univariate_Analysis",
        },
        {
          analysis_name: "Bivariate Analysis",
          analysis_id: "Bivariate_Analysis",
        },
        {
          analysis_name: "Outlier Analysis",
          analysis_id: "Outlier_Analysis",
        },
        {
          analysis_name: "Correlation Analysis",
          analysis_id: "Correlation_Analysis",
        },
        {
          analysis_name: "Time Series Analysis",
          analysis_id: "Time_Series_Analysis",
        },
      ],
      dropdowncategory: "analysis",
      labelName: "Analysis",
      displayMember: "analysis_name",
      isMultiple: false,
      placeholder: "Select Analysis",
      settingsKey: "analysis_settings",
    },
    {
      type: "dropdown",
      data: [],
      dropdowncategory: "asset",
      labelName: "Assets",
      displayMember: "asset",
      isMultiple: false,
      placeholder: "Select Asset",
      settingsKey: "asset_settings",
    },
    {
      type: "dropdown",
      data: [],
      maxTags: 4,
      subTitle: "Max 4 tags",
      dropdowncategory: "tag",
      labelName: "Tags",
      displayMember: "tag",
      isMultiple: true,
      placeholder: "Select Tags",
      settingsKey: "tag_settings",
    },
  ];
  selectedFilterValues = {
    analysis: {},
    asset: {},
    tag: [],
    startDate: moment().subtract(3, "days").startOf("day").toDate(),
    endDate: moment().endOf("day").toDate(),
  };
  assetsList = [];
  global_tagsList = {};
  htmlContent: any;
  generatedReportList = [];
  selectedReportToview = [];
  isValid = true;
  @ViewChild("loadedIframe", { static: true })
  loadedIframe: ElementRef<HTMLIFrameElement>;
  analysis_settings: IDropdownSettings = {
    singleSelection: true,
    idField: "analysis_id",
    textField: "analysis_name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  asset_settings: IDropdownSettings = {
    singleSelection: true,
    idField: "assetcode",
    textField: "asset",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  tag_settings: IDropdownSettings = {
    singleSelection: false,
    idField: "value",
    textField: "tag",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
  };
  constructor(
    public service: DataService,
    private sanitizer: DomSanitizer,
    private apiService: HttpcallsService,
    private fs: FirestoreService
  ) {}

  ngOnInit() {
    this.getFilterDataList();
    this.getGeneratedReport();
  }
  getFilterDataList() {
    this.service.activateloader(true);
    this.assetsList = [];
    let params = {
      collection: "LiveData",
      conditions: [{ key: "assettype", symbol: "!=", value: "Sensor" }],
    };
    this.fs
      .getFireBaseData(params)
      .then((response: any) => {
        if (response["status"] == "success") {
          this.prepareData(response["data"]);
        }
      })
      .catch((err) => {
        console.log(err);
        this.service.activateloader(false);
      });
  }
  prepareData(dataPoints: any) {
    if (dataPoints && dataPoints.length) {
      for (let docs of dataPoints) {
        const { asset, assetcode, department } = docs;
        this.assetsList.push({
          asset,
          assetcode,
          department,
        });
           this.global_tagsList[assetcode]=docs["tags"].map((item)=>{
          let {tag,value}=item
          return { tag, value };
        })
      }
      this.filtersList[2]["data"] = this.assetsList;
      this.selectedFilterValues["asset"] = [this.filtersList[2]["data"][0]];
      this.filtersList[3]["data"] = this.global_tagsList[this.selectedFilterValues["asset"][0]["assetcode"]];
      this.selectedFilterValues["tag"] = [this.filtersList[3]["data"][0]];
      this.selectedFilterValues["analysis"] = [this.filtersList[1]["data"][0]];
    }
    this.service.activateloader(false);
  }
  getSettings(type) {
    return this[type];
  }
  getGeneratedReport() {
    this.service.activateloader(true);
    let params = {
      collection: "Advanced_Analytics",
      conditions: [],
    };
    this.fs
      .getFireBaseData(params)
      .then((response: any) => {
        if (response["status"] == "success") {
          this.prepareReportList(response["data"]);
        }
      })
      .catch((err) => {
        console.log(err);
        this.service.activateloader(false);
      });
  }
  prepareReportList(dataPoints: any) {
    this.generatedReportList = [];
    if (dataPoints && dataPoints.length) {
      for (let reports of dataPoints) {
        let obj = { ...reports };
        obj["name"] = `${obj["analysis"]}_${obj["asset"]}`;
        this.generatedReportList.push(obj);
      }
      this.generatedReportList = _.orderBy(this.generatedReportList, (d) => {
return moment(d.timestamp, "DD MMMM YYYY [at] HH:mm:ss [UTC]Z").toDate();
}, 'desc')
      this.getFiles(this.generatedReportList[0]);
    }
  }
  onSelectionFilterChangeValue(value: any, filterField: any) {
    if (filterField["dropdowncategory"] == "analysis") {
      // this.selectedFilterValues["analysis"] = [value];
      if (
        value["analysis_id"] == "Correlation_Analysis" ||
        value["analysis_id"] == "Bivariate_Analysis"
      ) {
        if (this.selectedFilterValues["tag"].length >= 2) {
          this.isValid = true;
        } else {
          this.isValid = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${value["analysis_name"]} needs atleast 2 tags...`,
            showConfirmButton: false,
            timer: 2000,
            toast: true,
          });
        }
      }
    } else if (filterField["dropdowncategory"] == "asset") {
      // this.selectedFilterValues["asset"] = [value];
      this.filtersList[3]["data"] = this.global_tagsList[value["assetcode"]];
      this.selectedFilterValues["tag"] = [this.filtersList[3]["data"][0]];
    } else if (filterField["dropdowncategory"] == "tag") {
      // this.selectedFilterValues["tag"] = [value];
      if (
        this.selectedFilterValues["analysis"][0]["analysis_id"] ==
          "Correlation_Analysis" ||
        this.selectedFilterValues["analysis"][0]["analysis_id"] ==
          "Bivariate_Analysis"
      ) {
        if (this.selectedFilterValues["tag"].length >= 2) {
          this.isValid = true;
        } else {
          this.isValid = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `${this.selectedFilterValues["analysis"][0]["analysis_name"]} needs atleast 2 tags...`,
            showConfirmButton: false,
            timer: 2000,
            toast: true,
          });
        }
      }
    } else if (
      filterField["dropdowncategory"] == "date" &&
      value["startDate"]
    ) {
      this.validateDates(
        moment(value["startDate"]["_d"]).toDate(),
        moment(value["endDate"]["_d"]).toDate()
      ).then((isValid: boolean) => {
        this.isValid = isValid;
        if (isValid) {
          this.selectedFilterValues["startDate"] = moment(
            value["startDate"]["_d"]
          ).toDate();
          this.selectedFilterValues["endDate"] = moment(
            value["endDate"]["_d"]
          ).toDate();
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Maximum 7 days...",
            showConfirmButton: false,
            timer: 2000,
            toast: true,
          });
        }
      });
    }
  }
  validateDates(startDate, endDate) {
    return new Promise((resolve, reject) => {
      let diff = moment(endDate).diff(moment(startDate), "days");
      if (diff <= 7) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  getFiles(reportData: any) {
    this.service.activateloader(true);
    this.fs
      .getFileUrlfromFirestorage(
        `${reportData["report_path"]}${reportData["overall_report"]}`,
        3
      )
      .then((response) => {
        reportData["fileUrl"] = response["url"];
        this.loadHtmlFile(reportData);
      })
      .catch((err) => {
        this.service.activateloader(false);
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error getting files...",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
        });
      });
  }
  generateReport() {
    Swal.fire({
      position: "top-end",
      icon: "info",
      title: "Request has been sent successfully...",
      showConfirmButton: false,
      timer: 2000,
      toast: true,
    });
    let apiParams = {
      onload: true,
      analysis: this.selectedFilterValues["analysis"][0]["analysis_id"],
      start: moment(this.selectedFilterValues["startDate"]).format(
        "YYYY-MM-DD"
      ),
      end: moment(this.selectedFilterValues["endDate"]).format("YYYY-MM-DD"),
      asset_code: this.selectedFilterValues["asset"][0]["assetcode"],
      tags_list: [],
    };
    for (let tag of this.selectedFilterValues["tag"]) {
      apiParams["tags_list"].push(tag["tag"]);
    }
    this.apiService.generateAnalyticsReport(apiParams).subscribe(
      (response: any) => {
        if (response["status"] == "Success") {
          let obj: any = { ...response["Data"] };
          obj["name"] = `${obj["analysis"]}_${obj["asset"]}`;
          this.generatedReportList.unshift(obj);
          this.getFiles(obj);
        }
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Report generated successfully...",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
        });
        this.service.activateloader(false);
      },
      (err) => {
        this.service.activateloader(false);
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Report generation failed...",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
        });
      }
    );
  }
  loadHtmlFile(selecetedReport: any) {
    this.service.activateloader(false);
    let file = selecetedReport["fileUrl"].split("/");
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(
      selecetedReport["fileUrl"]
    );
    selecetedReport["htmlContent"] = this.htmlContent;
    this.selectedReportToview = [selecetedReport];
    this.service.activateloader(false);
    file = file.splice(3).join("/");
    const proxyUrl = `/firebase-api/${file}`;
    setTimeout(() => {
      this.updateIframeSrc(
        "report_content",
        this.selectedReportToview[0]["fileUrl"]
      );
    }, 1000);
    // this.http
    //   .get(selecetedReport['fileUrl'], {
    //     responseType: 'text',
    //     headers: new HttpHeaders({
    //       'Access-Control-Allow-Origin': '*',
    //       'Access-Control-Allow-Methods': 'GET',
    //       'Access-Control-Allow-Headers':
    //         'Origin, X-Requested-With, Content-Type, Accept',
    //     }),
    //   })
    //   .subscribe((data: string) => {
    //     this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(data);

    //     selecetedReport['htmlContent'] = this.htmlContent;
    //     this.selectedReportToview = [selecetedReport];
    //     this.utilitiesService.setLoaderValue(false);
    //   });
  }
  updateIframeSrc(id: string, url: string): void {
    const iframeElement = document.getElementById(id);
    if (iframeElement) {
      iframeElement.setAttribute("src", url);
      //       setTimeout(()=>{
      //  this.updatePlotsrc();
      //       },1000)
    }
  }
  downloadFile(type: string) {
    if (type == "html") {
      window.open(this.selectedReportToview[0]["fileUrl"]);
    } else if (type == "pdf") {
      // this.http
      //   .get(this.selectedReportToview[0]['fileUrl'], {
      //     responseType: 'text',
      //     headers: new HttpHeaders({
      //       'Access-Control-Allow-Origin': '*',
      //       'Access-Control-Allow-Methods': 'GET',
      //       'Access-Control-Allow-Headers':
      //         'Origin, X-Requested-With, Content-Type, Accept',
      //     }),
      //   })
      //   .subscribe((data: string) => {
      //     this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(data);
      //     console.log(this.htmlContent)
      //   },(err)=>{
      //     console.log(err)
      //   });
      let pdf = this.loadedIframe.nativeElement;
      // this.convertHtmltoImage(pdf).then((report: any) => {
      //   var imgWidth = 210;
      //   var imgHeight =
      //     (report.content.height * imgWidth) / report.content.width;
      //   var imgData = report.content.toDataURL('image/png');
      //   var doc = new jsPDF('p', 'mm', 'a4');
      //   const pageWidth = doc.internal.pageSize.getWidth();
      //   doc.text(`Data Analysis Report`, 73, 10, { align: 'left' });
      //   doc.setFontSize(10);
      //   doc.text(
      //     `${moment(this.selectedFilterValues['startDate']).format(
      //       'DD MMM YYYY'
      //     )} to ${moment(this.selectedFilterValues['endDate']).format(
      //       'DD MMM YYYY'
      //     )}`,
      //     15,
      //     20,
      //     { align: 'left' }
      //   );
      //   doc.text(
      //     `Analysis : ${this.selectedFilterValues['analysis']['analysis_name']}`,
      //     15,
      //     25,
      //     {
      //       align: 'left',
      //     }
      //   );
      //   doc.line(14, 27, pageWidth - 8, 27);
      //   doc.addImage(imgData, 'PNG', 0, 30, imgWidth, imgHeight);
      //   doc.save(this.selectedFilterValues['analysis']['analysis_id'] + '.pdf');
      // });
    }
  }
  // async convertHtmltoImage(element) {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       html2canvas(element, {
  //         allowTaint: true,
  //         useCORS: true,
  //       }).then((canvas) => {
  //         resolve({ status: "success", content: canvas });
  //       });
  //     } catch (err: any) {
  //       console.log(err);
  //       resolve({ status: "failure", message: err.toString() });
  //     }
  //   });
  // }
}

import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";

@Component({
  selector: "app-column-chart",
  templateUrl: "./column-chart.component.html",
  styleUrls: ["./column-chart.component.scss"],
})
export class ColumnChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  columnChartOptions = {};
  constructor() {}

  ngOnInit() {
    this.columnchart(this.data);
  }
  columnchart(data) {
    return (this.columnChartOptions = {
      chart: {
        type: "column",
        backgroundColor: "#2c2d31",
        marginTop: 20,
        marginLeft: 60,
        reflow:true,
      },
      title: {
        text: "",
        align: "left",
        style: { color: "white", fontSize: "16px", fontWeight: "lighter" },
        useHTML: false,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        labels: {
          autoRotation: 0,
          style: {
            color: "#bebfc0",
            fontSize: 10,
          },
        },
        title: {
          text: data.label_x,
          style: {
            color: "#bebfc0",
          },
        },
        categories: data.xaxis,
        crosshair: {
          className: undefined,
          color: "",
          dashStyle: "Solid",
          snap: true,
          width: 0.5,
          zIndex: 2,
        },
      },
      yAxis: {
        lineWidth: 1,
        tickInterval: data.tickinterval,
        endOnTick: false,
        gridLineColor: "#454245",
        labels: {
          style: {
            color: "#bebfc0",
          },
        },
        min: 0,
        title: {
          text: data.label_y,
          style: {
            color: "#bebfc0",
          },
        },
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        squareSymbol: true,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: "white",
          fontWeight: "ligther",
          fontSize:14
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}' +
          data.unit +
          "</b></td></tr>",
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: data.yaxis,
    });
  }
}

import {Directive, ElementRef, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {themes} from './themes.const';
import {Subscription} from 'rxjs';
import { DataService } from '../services/data.service';

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

  private themeName = 'oceanBlueThemProps';
  private serviceSubscription: Subscription;
  constructor( private elementRef: ElementRef,
               @Inject(DOCUMENT) private document: any,
               private service: DataService) { }

  ngOnInit() {
    this.updateTheme(this.themeName);
    this.service.getActiveTheme()
      .subscribe(themeName => {
        this.themeName = themeName ;
        this.updateTheme(this.themeName);
      });
  }
  updateTheme(themeName) {
    const element = this.elementRef.nativeElement;
    const them = themes[themeName];
    this.transition();
    for (const key in them) {
      element.style.setProperty(key, them[key]);
      this.document.body.style.setProperty(key, them[key]);
    }
  }
  transition(){
    document.documentElement.classList.add('transition');
    window.setTimeout(() =>{
      document.documentElement.classList.remove('transition')
    }, 500);
  }
  ngOnDestroy() {
    if (this.serviceSubscription) this.serviceSubscription.unsubscribe();
  }

}

import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";
@Component({
  selector: "app-line-bar-chart",
  templateUrl: "./line-bar-chart.component.html",
  styleUrls: ["./line-bar-chart.component.scss"],
})
export class LineBarChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  columnChartOptions: {};
  xaxis: any;
  columndata: any[];
  linedata: any[];
  constructor() {}
  ngOnInit() {
    this.xaxis = [];
    this.columndata = [];
    this.linedata = [];
    this.data.forEach((asset) => {
      this.xaxis.push(asset.assetName);
      this.columndata.push(parseInt(asset.mttr));
      this.linedata.push(parseInt(asset.mtbf));
    });
    this.chart();
  }

  chart() {
    let self = this;
    return (this.columnChartOptions = {
      chart: {
        backgroundColor: "#2c2d31",
        marginTop: 30,
        marginLeft: 40,
        reflow:true
      },
      title: {
        text: "",
        align: "left",
        style: { color: "black", fontSize: "16px" },
        useHTML: false,
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        categories: self.xaxis,
        crosshair: {
          className: undefined,
          color: "black",
          dashStyle: "Solid",
          snap: true,
          width: 0.5,
          zIndex: 2,
        },
      },
      yAxis: {
        gridLineColor: "#454245",
        lineWidth: 1,
        tickInterval: 100,
        endOnTick: false,
        min: 0,
        title: {
          text: "",
          style: {
            color: "black",
          },
        },

        labels: {
          style: {
            color: "black",
          },
          enabled: true,
        },
      },
      // tooltip: {
      //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      //   pointFormat:
      //     '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //     '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      //   footerFormat: "</table>",
      //   shared: true,
      //   useHTML: true,
      // },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
        line: { color: "#427bff" },
      },
      series: [
        {
          showInLegend: false,
          name: "MTTR",
          type: "column",
          data: self.columndata,
        },
        {
          showInLegend: false,
          marker: {
            enabled: false,
          },
          name: "MTBF",
          type: "line",
          data: self.linedata,
          tooltip: {
            valueSuffix: "",
          },
        },
      ],
    });
  }
}

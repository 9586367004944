import { style } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";

@Component({
  selector: "app-basicbar-chart",
  templateUrl: "./basicbar-chart.component.html",
  styleUrls: ["./basicbar-chart.component.scss"],
})
export class BasicbarChartComponent implements OnInit {
  chartOptions = {};
  @Input("data")
  set in(val) {
    this.plotChart(val);
  }
  highcharts = highcharts;
  constructor() {}

  ngOnInit() {}
  plotChart(data) {
    return (this.chartOptions = {
      chart: {
        type: data.type ? data.type : "column",
        backgroundColor: "transparent",
        height: data.height,
        marginBottom: data.marginBottom ? data.marginBottom : 90,
        marginLeft: data.marginLeft ? data.marginLeft : 60,
        marginTop: data.marginTop ? data.marginTop : 20,
        marginRight: data.marginRight ? data.marginRight : 80,
        style: {
          fontFamily: "SF Pro Text",
        },
      },
      colors: data.colors ? data.colors : ["#2A93FB", "#BFE4FF"],
      title: {
        text: "",
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointWidth: 20,
        },
        series: {
          dataLabels: {
            enabled: data.showlabel && data.showlabel == "show" ? true : false,
          },
        },
      },
      xAxis: data.xAxis ? data.xAxis : null,
      legend: data.legend ? data.legend : {},
      tooltip: {
        enabled: data.toolTip && data.toolTip == "hide" ? false : true,
        shared: true,
        // xDateFormat: "%d-%m-%y, %H:%M:%S",
        // headerFormat:
        //   '<span style="font-size:14px; font-weight:bolder">' +
        //   "<br/> </span>" +
        //   "<span style='font-size:14px;'>at {point.key}</span><table>",
        // pointFormat:
        //   '<tr style="font-size:14px;"><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //   `<td style="padding:0"><b>{point.y:.2f} ${data.unit}
        //   </b></td></tr>`,
        // footerFormat: "</table>",
        // useHTML: true,
        // backgroundColor: "#eeeeee",
        // borderColor: "#eeeeee",
      },
      yAxis: data.yAxis ? data.yAxis : null,
      // {
      //   title: {
      //     enabled: false,
      //   },
      // },
      series: data.data ? data.data : null,
    });
  }
}

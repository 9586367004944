import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-oee-topcard",
  templateUrl: "./oee-topcard.component.html",
  styleUrls: ["./oee-topcard.component.scss"],
})
export class OeeTopcardComponent implements OnInit {
  @Input() topCardData: any;
  title: string;
  progress: number;
  percentage: any;
  constructor() {}

  ngOnInit() {
    this.title = this.topCardData.title;
    this.progress = parseInt(this.topCardData.progress);
    this.percentage = parseInt(this.topCardData.percentage).toFixed(2);
  }
}

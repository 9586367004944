import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";

@Component({
  selector: "app-bar-chart",
  templateUrl: "./bar-chart.component.html",
  styleUrls: ["./bar-chart.component.scss"],
})
export class BarChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  barChartOptions: {};
  constructor() {}

  ngOnInit() {
    this.barchart(this.data);
  }
  barchart(data) {
    return (this.barChartOptions = {
      chart: {
        type: "bar",
        backgroundColor: "#2c2d31",
        marginTop: 30,
        reflow:true
      },
      title: {
        align: "left",
        text: "",
        style: { color: "white", fontSize: "16px" },
        useHTML: false,
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        squareSymbol: true,
        symbolHeight: 10,
        symbolWidth: 10,
        symbolRadius: 0,
        itemStyle: {
          color: "white",
          fontWeight: "ligther",
          fontSize: 14,
        },
      },
      xAxis: {
        vsible: false,
        categories: data.xaxis,
        labels: {
          style: {
            color: "#bebfc0",
          },
        },
        lineWidth: 0,
      },
      yAxis: {
        vsible: false,
        gridLineWidth: 0,
        min: 0,
        labels: {
          enabled: false,
          style: {
            color: "#bebfc0",
          },
        },
        title: {
          text: "",
        },
      },
      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      series: [
        {
          showInLegend: false,
          pointWidth: 20,
          name: "Downtime",
          data: data.yaxis,
        },
      ],
    });
  }
}

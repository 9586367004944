import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import _ from 'lodash';

@Component({
  selector: 'app-conversation-ai',
  templateUrl: './conversation-ai.component.html',
  styleUrls: ['./conversation-ai.component.scss']
})
export class ConversationAiComponent implements OnInit {
  converstaionAIData:any={}
  isLoad=false
  constructor(private fs :FirestoreService) { }

  ngOnInit() {
    this.converstaionAIData['mainContents']={"min-height":'485px',"max-height":"calc(100dvh - 300px)",
    questions:[
      ],
      imageSrc:{userIcon:'',aiIcon:'../../../../../assets/images/AI.jpg'}
    }
    this.getQuestions()
  }
  getQuestions() {
 this.fs.getQuestions().subscribe((data:any) => {
  let questions=_.orderBy(data,'questionId','asc')
      this.converstaionAIData['mainContents']['questions']=questions
      this.isLoad=true
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more.src";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge.src";
HC_solid_gauge(Highcharts);

@Component({
  selector: 'app-scatter-line-chart',
  templateUrl: './scatter-line-chart.component.html',
  styleUrls: ['./scatter-line-chart.component.scss']
})
export class ScatterLineChartComponent implements OnInit {
    options = {}
    @Input('data')
     set in(val){
        console.log(val)
         this.options  = {
            chart: {
                type: 'scatter',
                zoomType: 'xy'
            },
            accessibility: {
                description: '',
            },
            title: {
                text: ''
            },
            subtitle: {
                text: ''
            },
           xAxis: {
                title: {
                    text: 'SEC'
                },
                // startOnTick: true,
                // endOnTick: true,
                // showLastLabel: true
            },
            yAxis: {
                title: {
                    text: '% Down Time Energy'
                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'top',
                x: 100,
                y: 70,
                floating: true,
                backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
                borderWidth: 1
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="color:#427bff">{series.name}:</span>',
                        pointFormat: '<span style="font-size:14px">  {point.x} , {point.y} % </span>'
                    }
                }
            },
            series: [
              {
                type:'line',
                name: '',
                color: 'rgba(0, 0, 255)',
                data: val.line,
                showInLegend:false
            }, {
                type: 'scatter',
                name: 'SEC',
                color: 'rgba(0, 0, 255)',
                showInLegend:false,
                data: val.scatter
            }]
          }
        Highcharts.chart('container', this.options);

     }
      constructor() { }

      ngOnInit(){
      }

    }

import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more.src";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge.src";
HC_solid_gauge(Highcharts);
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() data: any;
  highcharts = Highcharts;
  piechartoptions = {};

  constructor() {
   }

  ngOnInit() {
    this.pieChart(this.data);
  }
pieChart(data){
  return( this.piechartoptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor:"transparent",
        style:{fontFamily: 'SF Pro Text'}
    },
    credits:{
      enabled:false,
      style:{
      color:'#999999',
      cursor:'pointer',
      fontSize:'9px',
      },
      text:'Highcharts.com'
      },
    title: {
        text: ''
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      squareSymbol: true,
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 0,
      itemStyle: {
         color: 'var(--graph-text-color)',
        fontWeight: "ligther",
        fontSize: 13,
      },
      y:10
    },
    tooltip: {
        headerFormat: '<span style="font-size:14px; font-weight:500">{point.key}</span><table>',
        pointFormat: '<tr style="font-size:14px"><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} ({point.description})</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        borderColor:'#eeeeee',
        backgroundColor:'#eeeeee',
      },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
   
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            size:"85%",
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
    },
    series: [{
        name: 'Assets',
        colorByPoint: true,
        data: [{
            name: 'Normal',
            y: data.good,
            color:"#6ac11c",
            borderColor:"#6ac11c",
            description:data.assets.normal,
            sliced: true,
            selected: true
        }, {
            name: "Warning",
            color:"#d9b006",
            description:data.assets.warning,
            borderColor:"#d9b006",
            y: data.warn
        }, {
            name: 'Critical',
            description:data.assets.alert,
            color:"#ff3917",
            borderColor:"#ff3917",
            y: data.critical
        }]
    }]
})
}
}

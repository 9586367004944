import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {HttpcallsService} from '../services/httpcalls.service';
import {CookieService} from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import {environment} from 'src/environments/environment';
import {element} from 'protractor';
import {UtilService} from '../services/util.service';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  authenticationUrl: any;
  hide : boolean = true;
  loginForm = new FormGroup({
    useremail: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [
      Validators.minLength(6),
      Validators.required,
    ]),
    remember: new FormControl(''),
  });
  loading: boolean = false;
  get UserEmail() {
    return this.loginForm.get('useremail');
  }
  get UserPassword() {
    return this.loginForm.get('password');
  }
  constructor(
    private db: AngularFirestore,
    private router: Router,
    private cookie: CookieService,
    private http: HttpcallsService,
    private auth: AngularFireAuth,
    private utilservice: UtilService,
  ) {}
  ngOnInit() {
    this.authenticationUrl = environment.authencationApi;
    this.router.navigate(['/login']);
    if (this.cookie.check('user') && this.cookie.check('password')) {
      this.loginForm.get('useremail').setValue(this.cookie.get('user'));
      this.loginForm.get('password').setValue(this.cookie.get('password'));
      this.loginForm.get('remember').setValue(true);
    } else {
      this.cookie.delete('useremail');
      this.cookie.delete('password');
      this.loginForm.get('remember').setValue(false);
    }
  }
  login(data: any) {
    this.loading = true;
    var json = {
      useremail: data.value.useremail,
      password: data.value.password,
      remember: data.value.remember,
    };
    // let check = json.useremail.includes("@assethealth.com");
    if (true) {
      let data = {userid: json.useremail, password: json.password};
      if (environment.SERVICE_CALL == 'http') {
        this.http.login(data).subscribe((data2: any) => {
          if (data2.token) {
            this.router.navigate([
              '/common/predictive-maintenance/pm/overview',
            ]);
            localStorage.setItem('user', data2.userId);
            localStorage.setItem('token', data2.token);
            localStorage.setItem('role', data2.role);
            localStorage.setItem('levelName', data2.name);
            localStorage.setItem('tab', '0');
            localStorage.setItem('selected', 'assethealth');
            localStorage.setItem('selectednode', 'Sona BLW');
            if (json.remember) {
              this.cookie.set('user', json.useremail, 365);
              this.cookie.set('password', json.password, 365);
            } else {
              this.cookie.delete('user');
              this.cookie.delete('password');
            }
          } else {
            this.loading = false;
            Swal.fire('Sorry! Wrong Credentials');
          }
        });
      } else {
        let requestBody: any = {
          productId: 'SONA_BLW',
        };
        requestBody['email'] = data.userid;
        requestBody['password'] = data.password;
        let body: any = {
          encrypted: '',
        };
        body['encrypted'] = this.utilservice.encrypt(
          requestBody,
          environment.authenticationKey,
        );
        let endPoint = 'signIn';
        this.http
          .blpUsersAuthentication(body, `${this.authenticationUrl}/${endPoint}`)
          .subscribe((response: any) => {
            let result: any = this.utilservice.decrypt(
              response['encrypted'],
              environment.authenticationKey,
            );
            result = JSON.parse(result);
            let details: any;
            if (result.status == 'success') {
              let access = {
                access: result.accessToken,
                refresh: result.refreshToken,
              };
              let enc = this.utilservice.encrypt(
                access,
                environment.localToken,
              );
              localStorage.setItem('access', enc);
              let encrypted = this.utilservice.encrypt(
                {email: data.userid},
                environment.firebasetokenKey,
              );
              this.http
                .getToken({encrypted: encrypted})
                .subscribe((res: any) => {
                  let decrypt = this.utilservice.decrypt(
                    res['encrypted'],
                    environment.firebasetokenKey,
                  );
                  decrypt = JSON.parse(decrypt)
                  this.auth
                    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
                    .then(() => {
                      this.auth
                        .signInWithCustomToken(decrypt.token)
                        .then(user => {
                          let requestBody:any=  {
                            username: "admin@blpadmin.co.in",
                            password: "Sjq73cdd5SAhs"
                        }
                          this.http.getAccessToke(requestBody).subscribe((res:any)=>{
                            this.utilservice.setToken(res)
                            localStorage.setItem('SONA_tokens',JSON.stringify(res))

                          })
                          let fetcheddata = [];
                          fetcheddata.push(result['details']);
                          localStorage.setItem('user', data.userid);
                          localStorage.setItem(
                            'report_user',
                            fetcheddata[0].user ? fetcheddata[0].user : 'user',
                          );
                          localStorage.setItem('tab_index', '0');
                          localStorage.setItem('selected', 'assethealth');
                          localStorage.setItem('role', fetcheddata[0].role);
                          localStorage.setItem('levelid', fetcheddata[0].name);
                          localStorage.setItem('department',JSON.stringify(fetcheddata[0]['department']))
                          localStorage.setItem(
                            'selectednode',
                            JSON.stringify({
                              id: fetcheddata[0].name,
                              level: fetcheddata[0].role,
                            }),
                          );

                          this.router.navigate([
                            '/common/predictive-maintenance/pm/overview',
                          ]);
                          if (json.remember) {
                            this.cookie.set('user', json.useremail, 365);
                            this.cookie.set('password', json.password, 365);
                          } else {
                            this.cookie.delete('user');
                            this.cookie.delete('password');
                          }
                        }).catch((err) =>{
                          Swal.fire('Sorry! Authentication Failed');
                        });
                    }).catch((err) =>{
                      Swal.fire('Sorry! Authentication Failed');
                    })
                });
            } else {
              {
                this.loading = false;
                Swal.fire('Sorry! Wrong Credential');
              }
            }
          });
        // this.db
        //   .collection("/Asset_OEE/SONA/User", (ref) =>
        //     ref
        //       .where("userid", "==", data.userid)
        //       .where("password", "==", data.password)
        //   )
        //   .get()
        //   .subscribe((d) => {
        //     if (d.size == 1)
        //     {
        //       let fetcheddata = [];
        //       d.forEach(element => {
        //         fetcheddata.push(element.data())
        //       })
        //       localStorage.setItem("user", data.userid);
        //     localStorage.setItem("pw", data.password);
        //       localStorage.setItem("report_user", fetcheddata[0].user ? fetcheddata[0].user : "user");
        //       localStorage.setItem("tab_index", "0");
        //       localStorage.setItem("selected", "assethealth");
        //       localStorage.setItem('role', fetcheddata[0].role);
        //       localStorage.setItem('levelid', fetcheddata[0].name);
        //       localStorage.setItem("selectednode", JSON.stringify({ id: fetcheddata[0].name, level: fetcheddata[0].role }));

        //         this.router.navigate(["/common/predictive-maintenance/pm/overview"])

        //       if (json.remember)
        //       {
        //         this.cookie.set("user", json.useremail, 365);
        //         this.cookie.set("password", json.password, 365);
        //       } else
        //       {
        //         this.cookie.delete("user");
        //         this.cookie.delete("password");
        //       }
        //     }
        //     else
        //     {
        //       this.loading = false;
        //       Swal.fire("Sorry! Wrong Credential");
        //     }
        //   });
      }
    } else {
      this.loading = false;
      Swal.fire('Sorry! Wrong Credential');
    }
  }
  forgot() {
    this.router.navigate(['/forgot']);
  }
  passwordView() {
    this.hide = !this.hide;
  }
}

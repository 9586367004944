import { Component, Input, OnInit } from "@angular/core";
import * as highcharts from "highcharts";

@Component({
  selector: "app-basiccolumn-chart",
  templateUrl: "./basiccolumn-chart.component.html",
  styleUrls: ["./basiccolumn-chart.component.scss"],
})
export class BasiccolumnChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  columnChartOptions = {};
  chartOptions = {};
  constructor() {}

  ngOnInit() {
    this.plotChart(this.data);
  }

  plotChart(data) {
    return (this.chartOptions = {
      chart: {
        type: "column",
        backgroundColor: "transparent",
        scrollablePlotArea: {
          minWidth: 100,
        },
        // spacing:0
      },
      title: {
        text: "",
      },

      xAxis: {
        categories: data.categories,
        title: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
      },
      yAxis: [
        {
          title: {
            enabled: false,
          },
        },
        {
          title: {
            enabled: false,
          },
          opposite: true,
        },
      ],
      colors: ["#2A93FB", "#BFE4FF"],
      column: {
        colorByPoint: true,
      },
      plotOptions: {
        column: {
          maxPointWidth: 50,
        },
      },
      legend: {
        verticalAlign: "top",
        layout: "horizontal",
        itemMarginTop: 0,
        symbolRadius: 0,
        symbolHeight: 10,
        symbolWidth: 10,
      },
      series: data.series,
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import * as highcharts from "highcharts";

@Component({
  selector: 'app-tripple-spline-chart',
  templateUrl: './tripple-spline-chart.component.html',
  styleUrls: ['./tripple-spline-chart.component.scss']
})
export class TrippleSplineChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  columnChartOptions = {};
  chartOptions = {}
  constructor() { }

  ngOnInit() {
    this.plotChart();
  }
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
        chart.reflow();
    },100);
}
  plotChart() {
    
    return this.chartOptions = {
      chart: {
        type: 'spline',
        
    },
    title: {
        text: '',
    },
    xAxis: {
        type: 'datetime',
        labels: {
            overflow: 'justify'
        }
    },
    yAxis: {
        tickWidth: 1,
        title: {
            text: ''
        },
        lineWidth: 1,
        opposite: false
    },
    colors: [
      '#2A93FB',
      '#FFB083',
      '#B936AE',
      ],    
    column: {
      colorByPoint: true
  },
    tooltip: {
        split: true,
        shape: 'sqaure',
    },

    plotOptions: {
        spline: {
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            marker: {
                enabled: false
            },
            pointInterval: 3600000, // one hour
            pointStart: Date.UTC(2022, 2, 10, 0, 0, 0),
            pointEnd: Date.UTC(2022, 2, 25, 0, 0 ,0 )
        }
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      margin: 25,
      itemMarginTop: 0,
      symbolRadius: 0,
      symbolHeight: 10,
      symbolWidth: 10
    },
    series: [{
        name: 'SEC',
        type: undefined,
        data: [0.2, 0.8, 0.8, 0.8, 1, 1.3, 1.5, 2.9, 1.9, 2.6, 1.6, 3, 4, 3.6,
            5.5]

    }, 
    {
      name: 'Downtime',
      type: undefined,
      data: [0, 0, 0.6, 0.9, 0.8, 0.2, 0, 0, 0, 0.1, 0.6, 0.7, 0.8, 0.6, 0.2]
  },
  {
    name: 'Downtime',
    type: undefined,
    data: [0, 0.2, 0.5, 0.6, 0.9, 0.35, 0.3, 0.2,1.5, 2.35, 1.02, 2.15, 3.35, 3,
      4.3]
}
  ]
};
    }
}


import { Component, Input, OnInit } from '@angular/core';
import * as highcharts from "highcharts";

@Component({
  selector: 'app-single-spline-chart',
  templateUrl: './single-spline-chart.component.html',
  styleUrls: ['./single-spline-chart.component.scss']
})
export class SingleSplineChartComponent implements OnInit {
  @Input() data: any;
  highcharts = highcharts;
  columnChartOptions = {};
  chartOptions = {}
  constructor() { }

  ngOnInit() {
    this.plotChart();
  }
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
        chart.reflow();
    },100);
}
  plotChart() {
    
    return this.chartOptions = {
      chart: {
        type: 'spline',
        
    },
    title: {
        text: '',
    },
    xAxis: {
        type: 'datetime',
        labels: {
            overflow: 'justify'
        }
    },
    yAxis: {
        tickWidth: 1,
        title: {
            text: ''
        },
        lineWidth: 1,
        opposite: false
    },
    colors: [
      '#FFB083',
      ],    
    column: {
      colorByPoint: true
  },
    tooltip: {
        split: true,
        shape: 'sqaure',
    },

    plotOptions: {
        spline: {
            lineWidth: 2,
            states: {
                hover: {
                    lineWidth: 3
                }
            },
            marker: {
                enabled: false
            },
            pointInterval: 3600000, // one hour
            pointStart: Date.UTC(2022, 2, 5, 0, 0, 0),
        }
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      margin: 25,
      itemMarginTop: 0,
      symbolRadius: 0,
      symbolHeight: 10,
      symbolWidth: 10
    },
    series: [{
        name: 'SEC',
        type: undefined,
        data: [0.2, 0.8, 0.8, 0.8, 1, 1.3, 1.5, 2.9, 1.9, 2.6, 1.6, 3, 4, 3.6,
            5.5],

    }, 
  
   
  ]
};
    }
}



import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more.src";
HighchartsMore(Highcharts);
import HC_solid_gauge from "highcharts/modules/solid-gauge.src";
HC_solid_gauge(Highcharts);

@Component({
  selector: "app-solid-gauge-chart",
  templateUrl: "./solid-gauge-chart.component.html",
  styleUrls: ["./solid-gauge-chart.component.scss"],
})
export class SolidGaugeChartComponent implements OnInit {
  @Input() data: any;
  highcharts = Highcharts;

  solidGaugeChartOptions = {};
  constructor() {}

  ngOnInit() {
    this.solidgauagechart(this.data);
  }
  solidgauagechart(data) {
    return (this.solidGaugeChartOptions = {
      chart: {
        type: 'gauge',
        backgroundColor:"transparent",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        marginTop:40,
        style:{fontFamily: 'SF Pro Text'}
    },
    credits:{
      enabled:false,
      style:{
      color:'#999999',
      cursor:'pointer',
      fontSize:'9px',
      },
      text:'Highcharts.com'
      },
    title: {
        text: ''
    },

    pane: {
        startAngle: -90,
        endAngle: 90,
        background:null,
        size:260
    },

    // the value axis
    yAxis: {
        min: 0,
        max: 100,

        minorTickInterval: 'none',
        tickPixelInterval: 100,
        tickWidth: 2,
        tickPosition: 'outside',
        tickLength: 15,
        tickColor: '#494a49',
        labels: {
            step: 1,
            rotation: 0,
            distance: 27,
            style:{
              color: 'var(--graph-text-color)',
              }
        },
        title: {
          text:`<div style="display:flex;flex-direction: column;align-items: center;">
          <div style = "color: #209b63; font-size:20px; font-weight:400">${(data.y.toFixed(2))}%</div>
          <div style = "color:#209b63; font-size:20px;">Healthy</div>
          </div>`,
          verticalAlign:"bottom",
          y:120,
          useHTML:true
      },
        plotBands: [{
            from: 0,
            to: 100,
            color: '#00c120', // green
            innerRadius: "60%"
        }, 
        {
            from: 0,
            to: 30,
            color: '#e07876', // red
            innerRadius: "100%",
            outerRadius:"104%"

          }, 
          {
            from:30,
            to: 70,
            color: '#d9b006', // yellow
            innerRadius:"100%",
            outerRadius:"104%"
            },
             {
            from:70,
            to: 100,
            color: '#2cbb70', // green
            innerRadius:"100%",
            outerRadius:"104%"
            }]
        },
        tooltip: {
          headerFormat:
        '<span style="font-size:12px; font-weight:bolder">' +
        data.label_y + "<br/> </span>",
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.2f} %',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
        backgroundColor:"#eeeeee",
        borderColor:"#eeeeee",
      },
	plotOptions: {
			gauge: {
				dial: {
					radius: '100%',
					backgroundColor: '#424242',
					borderColor: '#424242',
					borderWidth: 1,
					baseWidth: 12,
					topWidth: 1,
          bottomWidth:20,
					baseLength: '0%', // of radius
					rearLength: '0%'
				},
        pivot:{
          backgroundColor:'#424242',
          borderColor:'#424242',
          borderWidth:2,
           radius:5
       },
       dataLabels:{
       enabled:false
       }
			}
		},
    series: [{
        name: 'Health',
        data: [(data.y)],
    }]
    });
  }
}

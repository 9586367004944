import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { MatSnackbarSeverity, SnackbarSeverity } from "mat-snackbar-severity";
import { BehaviorSubject, Observable } from "rxjs";
import moment from "moment";
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  showLoader$: BehaviorSubject<any> = new BehaviorSubject(null);
  tokenValue$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private router: Router,
    private snackBarSeverity: MatSnackbarSeverity
  ) {}
  getLoaderValue(): Observable<any> {
    return this.showLoader$.asObservable();
  }
  setLoaderValue(value) {
    this.showLoader$.next(value);
  }

  showSnakbarMessage(
    severity: any,
    message: string,
    horizontal: any,
    vertical: any
  )
  {
    this.snackBarSeverity.open(severity, message, "", {
      duration: 5000,
      // panelClass: ['blue-snackbar'],
      horizontalPosition: horizontal,
      verticalPosition: vertical,

    });
  }
  encrypt(value : any,key:any){
    try{
      key=key
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value),key).toString()
      return encrypted
    }
   catch(err){
    return null
   }

  }
  decrypt(text :any, key){
    try{
     key=key
      const decrypted = CryptoJS.AES.decrypt(text, key);
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    }
 catch(err){
  return null
 }
  }
  setToken(data:any){
    this.tokenValue$.next(data);
  }
}
